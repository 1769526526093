import "./Solutions.scss";
// Components import
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Landing from "./Sections/Landing/Landing";
import SolutionsSection from "./Sections/SolutionsSection/SolutionsSection";
import ScrollHeader from "../../Components/ScrollHeader/ScrollHeader";


let Solutions = () => {


  window.scrollTo(0,0)


  return (
    <>
      {/* <ScrollHeader /> */}
      {/* <Header /> */}
      <Landing />
      <SolutionsSection />
      {/* <Footer /> */}
    </>
  );
};

export default Solutions;
