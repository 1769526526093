import './Contact.scss'
// Components Import:
import Landing from './Section/Landing/Landing'
import ContactInfo from './Section/ContactInfo/ContactInfo'
import Footer from '../../Components/Footer/Footer'
import SentNotification from '../../Components/SentNotification/SentNotification'
import React from 'react'



const Contact = () => {
  window.scrollTo(0,0)

  // Show Notification
  let [shown, setShown] = React.useState(false)

  return (
    <div className="contact-page">
      {shown && <SentNotification/>}
      <Landing />
      <ContactInfo setShown={setShown}/>
      {/* <Footer /> */}

    </div>
  )
}

export default Contact