import { useContext } from "react";
import { context } from "../../../../App";
import "./Landing.scss";

const Landing = () => {
  let isArabic = useContext(context)?.isArabic

  let landingHeader = useContext(context)?.headers?.products_page
  let arabicLandingHeader = useContext(context)?.headers?.arabic_products_page

  return (
    <div className={`landing products-landing d-flex flex-column justify-content-end overflow-hidden ${isArabic && 'arabic'}`}>
      <p className="title">{isArabic? "منتجاتنا": "Our Products"}</p>
      <p className="desc">
        {isArabic? arabicLandingHeader : landingHeader}
      </p>
      <div className="landing-shape">
        <svg
          width="418"
          height="441"
          viewBox="0 0 418 441"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M287.375 60.3751V105.654L326.563 83.0136L418 120.748L404.938 218.857L365.749 241.499L404.937 264.14L418 362.25L326.562 399.984L287.375 377.344V422.625L209 483L130.625 422.625V377.344L91.4375 399.984L1.85984e-05 362.25L13.0625 264.14L52.2514 241.499L13.0625 218.857L0 120.748L91.4375 83.0136L130.625 105.654V60.3751L209 0L287.375 60.3751ZM137.156 63.5918L209 8.24804L280.844 63.5918V109.428L222.063 143.389L210.223 232.31L209 233.252L207.777 232.31L195.937 143.389L137.156 109.428V63.5918ZM58.7826 245.272L19.112 268.192L7.13806 358.126L90.9558 392.716L130.625 369.797V301.875L201.658 247.156L201.862 245.624L200.432 245.034L117.562 279.233L58.7826 245.272ZM191.867 241.499L118.044 271.964L65.3139 241.499L118.044 211.034L191.867 241.499ZM200.432 237.964L117.563 203.765L58.7826 237.725L19.112 214.806L7.13804 124.872L90.9557 90.2822L130.625 113.201V181.125L201.658 235.845L201.862 237.374L200.432 237.964ZM200.434 256.346L137.156 305.092V366.023L189.888 335.557L200.434 256.346ZM137.156 373.57L195.938 339.609L207.776 250.69L209 249.748L210.224 250.69L222.062 339.609L280.844 373.57V419.408L209 474.752L137.156 419.408V373.57ZM228.112 147.44L280.844 116.975V177.908L217.565 226.654L228.112 147.44ZM287.375 181.125L216.342 235.845L216.138 237.374L217.568 237.964L300.438 203.765L359.217 237.725L398.888 214.806L410.862 124.872L327.044 90.2822L287.375 113.201V181.125ZM300.438 279.233L217.568 245.034L216.138 245.624L216.342 247.156L287.375 301.875V369.797L327.044 392.716L410.862 358.126L398.888 268.192L359.217 245.272L300.438 279.233ZM299.956 271.964L352.686 241.499L299.956 211.034L226.133 241.499L299.956 271.964ZM217.566 256.346L280.844 305.092V366.023L228.112 335.557L217.566 256.346ZM189.888 147.44L200.435 226.654L137.156 177.908V116.975L189.888 147.44Z"
            fill="url(#paint0_radial_24_2496)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_24_2496"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(209 241.5) rotate(56.5065) scale(349.143 302.284)"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <div className="stars">
        <svg
          width="53"
          height="53"
          viewBox="0 0 53 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.855469 33.1603C2.61014 33.0793 4.09486 32.4044 5.30963 31.1357C6.5244 29.8669 7.14529 28.3552 7.14529 26.6005C7.14529 28.3552 7.73918 29.8669 8.95395 31.1357C10.1687 32.4044 11.6534 33.0793 13.4081 33.1603C11.6534 33.2413 10.1687 33.9162 8.95395 35.1849C8.36664 35.783 7.90425 36.4921 7.59373 37.2707C7.28321 38.0493 7.13077 38.8819 7.14529 39.7201C7.14529 37.9654 6.5514 36.4537 5.30963 35.1849C4.09486 33.9162 2.61014 33.2413 0.855469 33.1603ZM7.14529 13.724C10.5466 13.562 13.4351 12.2392 15.8107 9.7557C18.1862 7.27217 19.374 4.32972 19.374 0.901367C19.374 4.32972 20.5618 7.27217 22.9373 9.7557C25.3129 12.2392 28.2013 13.535 31.6297 13.724C29.3891 13.8319 27.3375 14.4528 25.4478 15.6406C23.5852 16.8014 22.1005 18.3671 20.9937 20.3107C19.9139 22.2544 19.374 24.333 19.374 26.6005C19.374 23.1722 18.1862 20.2027 15.8107 17.7192C13.4351 15.2087 10.5466 13.8859 7.14529 13.724ZM16.1076 42.7975C18.6721 42.6895 20.8587 41.6907 22.6404 39.8281C24.422 37.9654 25.3129 35.7518 25.3129 33.1603C25.3129 35.7518 26.2037 37.9654 27.9854 39.8281C29.767 41.6907 31.9266 42.6895 34.4911 42.7975C31.9266 42.9055 29.767 43.9043 27.9854 45.7669C26.2037 47.6296 25.3129 49.8432 25.3129 52.4347C25.3129 49.8432 24.422 47.6296 22.6404 45.7669C20.9375 43.9572 18.5908 42.8905 16.1076 42.7975ZM34.4911 30.0019C37.0557 29.8939 39.2153 28.8951 40.9969 27.0325C42.7786 25.1698 43.6424 22.9562 43.6424 20.3377C43.6424 22.9292 44.5333 25.1428 46.3149 27.0055C48.0966 28.8681 50.2832 29.8669 52.8477 29.9749C50.2832 30.0829 48.0966 31.0817 46.3149 32.9443C44.5333 34.807 43.6424 37.0206 43.6424 39.6121C43.6424 37.0206 42.7516 34.807 40.9969 32.9443C39.2153 31.1087 37.0557 30.1099 34.4911 30.0019Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default Landing;
