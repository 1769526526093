import React from 'react'

const StarsSVG = () => {
  return (
    <div className="stars">
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.855469 33.1603C2.61014 33.0793 4.09486 32.4044 5.30963 31.1357C6.5244 29.8669 7.14529 28.3552 7.14529 26.6005C7.14529 28.3552 7.73918 29.8669 8.95395 31.1357C10.1687 32.4044 11.6534 33.0793 13.4081 33.1603C11.6534 33.2413 10.1687 33.9162 8.95395 35.1849C8.36664 35.783 7.90425 36.4921 7.59373 37.2707C7.28321 38.0493 7.13077 38.8819 7.14529 39.7201C7.14529 37.9654 6.5514 36.4537 5.30963 35.1849C4.09486 33.9162 2.61014 33.2413 0.855469 33.1603ZM7.14529 13.724C10.5466 13.562 13.4351 12.2392 15.8107 9.7557C18.1862 7.27217 19.374 4.32972 19.374 0.901367C19.374 4.32972 20.5618 7.27217 22.9373 9.7557C25.3129 12.2392 28.2013 13.535 31.6297 13.724C29.3891 13.8319 27.3375 14.4528 25.4478 15.6406C23.5852 16.8014 22.1005 18.3671 20.9937 20.3107C19.9139 22.2544 19.374 24.333 19.374 26.6005C19.374 23.1722 18.1862 20.2027 15.8107 17.7192C13.4351 15.2087 10.5466 13.8859 7.14529 13.724ZM16.1076 42.7975C18.6721 42.6895 20.8587 41.6907 22.6404 39.8281C24.422 37.9654 25.3129 35.7518 25.3129 33.1603C25.3129 35.7518 26.2037 37.9654 27.9854 39.8281C29.767 41.6907 31.9266 42.6895 34.4911 42.7975C31.9266 42.9055 29.767 43.9043 27.9854 45.7669C26.2037 47.6296 25.3129 49.8432 25.3129 52.4347C25.3129 49.8432 24.422 47.6296 22.6404 45.7669C20.9375 43.9572 18.5908 42.8905 16.1076 42.7975ZM34.4911 30.0019C37.0557 29.8939 39.2153 28.8951 40.9969 27.0325C42.7786 25.1698 43.6424 22.9562 43.6424 20.3377C43.6424 22.9292 44.5333 25.1428 46.3149 27.0055C48.0966 28.8681 50.2832 29.8669 52.8477 29.9749C50.2832 30.0829 48.0966 31.0817 46.3149 32.9443C44.5333 34.807 43.6424 37.0206 43.6424 39.6121C43.6424 37.0206 42.7516 34.807 40.9969 32.9443C39.2153 31.1087 37.0557 30.1099 34.4911 30.0019Z"
        fill="white"
      />
    </svg>
  </div>
  )
}

export default StarsSVG