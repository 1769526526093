import axios from "axios";
import React, { useContext } from "react";
import { context } from "../../../../App";
import "./ProjectsSec.scss";
import { Link } from "react-router-dom";

const ProjectsSec = () => {
  let isArabic = useContext(context)?.isArabic;

  // Remote Data
  const projectsURL = `${useContext(context)?.URL}/projects/`;
  const [projects, setProjects] = React.useState([]);

  React.useEffect(() => {
    axios.get(projectsURL).then((response) => setProjects(response.data));
  }, []);

  // Show more Function:

  let [count, setCount] = React.useState(8);

  function showMore() {
    setCount((prevCount) => prevCount + 8);
    setTimeout(() => {
      let box = document.querySelectorAll(".project")[count] as HTMLElement;
      window.scrollTo(0, box.offsetTop);
    }, 100);
  }

  return (
    <div className="projects-section text-center" style={isArabic ? { direction: "rtl" } : {}}>
      <div className="section-info">
        <p className="heading">{isArabic ? "المشاريع" : "Projects"}</p>
      </div>
      <div className="projects-container">
        {projects.map(({ id, name, arabic_name, image, slug }, i) => {
          if (i > count - 1) return;
          return (
            <div className="project" key={id}>
              <img src={image} alt={name} />
              <div className="info">
                <p className="project-name">{isArabic ? arabic_name : name}</p>
                {slug && (
                  <Link to={`/projects/${slug}`} className="see-more-btn">
                    {isArabic ? "المزيد" : "More"}
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <button className="see-more" onClick={showMore}>
        {isArabic ? "عرض المزيد" : "See More"}
      </button>
    </div>
  );
};

export default ProjectsSec;
