import "./SolutionsSection.scss";
// Components Import
import SolutionPreview from "../../../../Components/SolutionPreview/SolutionPreview";
import SolutionBox from "../../../../Components/SolutionBox/SolutionBox";
// SVG Imports
import CityWideSVG from "../../Svg/CityWide";
import VideoSVG from "../../Svg/VideoSVG";
import PeopleSVG from "../../Svg/PeopleSVG";
import ScopeSVG from "../../Svg/ScopeSVG";
import AudioSVG from "../../Svg/AudioSVG";
import WirelessSVG from "../../Svg/WirelessSVG";
import React, { useContext } from "react";
// Data Import 
import {solutionDetails} from '../../../../Assets/Data/Data'
import { SolutionDetails, BranchesDetails } from "../../../../Assets/typeScript/interfaces";
import axios from "axios";
import { context } from "../../../../App";
// Interfaces Import


let SolutionsSection = () => {
  let isArabic = useContext(context)?.isArabic
  // Remote Data
  const solutionsURL = `${useContext(context)?.URL}/solutions_page/`
  const [solutions, setSolutions] = React.useState<SolutionDetails[]>([])

  React.useEffect(() => {
    axios.get(solutionsURL)
    .then(response => setSolutions(response.data))
  }, [])



  

  // Active ox Id
  let [active, setActive] = React.useState(-1);
  // Data To be shown
  let [activeData, setActiveData] = React.useState<SolutionDetails | null>(null);
  // show solution under 'x' row
  let [row, setRow] = React.useState(0);

  // acivate Solution / Solution Preview
  function activateSolution(e: EventTarget, id: number) {
    let pressed: boolean = false;
    if (e instanceof HTMLElement) {
      pressed = e.classList.contains("active");
    }
    // activate box :
    // remove class 'active' from all elements
    let boxes = document.querySelectorAll(".solution-box");
    for (let i = 0; i < boxes.length; i++) {
      boxes[i].classList.remove("active");
    }
    // add class 'active' to pressed element
    if (e instanceof HTMLElement) {
      if (!pressed) {
        // show solution preview
        setActiveData(solutions[id-1]);
        // set active box id
        setActive(id-1);
        id <= 3 ? setRow(1) : setRow(2);

        e.classList.add("active");
        setTimeout(() => {
          let preview = document.querySelector(
            ".solution-preview"
          ) as HTMLElement;
          window.scrollTo(0, preview.offsetTop);
        }, 150);
      } else if (pressed) {
        // Remove Solution Preview
        setActiveData(null);
        setActive(-1);
        setRow(0);

        e.classList.remove("active");
      }
    }
  }

  return (
    <div className="solutions-section">
      <p className="section-title heading" style={isArabic? {direction: 'rtl'}: {}}>{isArabic? "الحلول": "Solutions"}</p>
      
      <div className="solutions-container">
        <div className="solutions-group">
          {window.innerWidth < 778 &&
            solutions?.map((solution: SolutionDetails) => {
              return (
                <>
                  <SolutionBox
                    {...solution}
                    activateSolution={activateSolution}
                    active={active == solution.id-1 ? true : false}
                    key={solution.id}
                  />
                  {window.innerWidth < 778 && active == solution.id-1 && (
                    activeData && <SolutionPreview {...activeData}/>
                  )}
                </>
              );
            })}
        </div>

        {window.innerWidth > 778 && (
          <>
            <div className="solutions-group mb-5">
              {solutions.map((solution) => {
                if (solution.id > 3) return;
                return (
                  <SolutionBox
                    {...solution}
                    activateSolution={activateSolution}
                    active={active == solution.id-1 ? true : false}
                    key={solution.id}
                  />
                );
              })}
            </div>
            {row == 1 && activeData && <SolutionPreview {...activeData} />}
            <div className="solutions-group">
              {solutions.map((solution) => {
                if (solution.id <= 3) return;
                return (
                  <SolutionBox
                    {...solution}
                    activateSolution={activateSolution}
                    active={active == solution.id-1 ? true : false}
                    key={solution.id-1}
                  />
                );
              })}
            </div>
            {row == 2 && activeData && <SolutionPreview {...activeData} />}
          </>
        )}
      </div>
    </div>
  );
};

export default SolutionsSection;
