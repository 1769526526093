import './SentNotification.scss'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'


const SentNotification = () => {

// Hide Notification after 7s
let [hide, setHide] = React.useState(false)

React.useEffect(() => {

  setTimeout(() => {
    setHide(true)
  }, 7000)
}, [])

  return (
    <div className={`notification d-flex align-items-center justify-content-start p-3 ${hide && 'd-none'}`}>
      <FontAwesomeIcon icon={faCheck} className='me-3' />
      <p>Notification Sent</p>
    </div>
  )
}

export default SentNotification