import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { context } from "../../App";
import {
  Product,
  SolutionDetails,
  WebsiteData,
} from "../../Assets/typeScript/interfaces";
import "./Footer.scss";

let Footer: React.FC<WebsiteData> = ({
  phone_number,
  whatsapp_link,
  email,
  linkedin_link,
  facebook_link,
  twitter_link,
}) => {
  let isArabic = useContext(context)?.isArabic;

  const solutionsURL = `${useContext(context)?.URL}/solutions_page/`
  // const solutionsURL = `http://127.0.0.1:8000/api/solutions_page/`;
  const productsURL = `${useContext(context)?.URL}/products/`;
  // const productsURL = `http://127.0.0.1:8000/api/products`;
  
  let [solutions, setSolutions] = useState<SolutionDetails[]>([]);
  let [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    // get solutions data
    axios.get(solutionsURL).then((response) => {
      setSolutions(response.data);
    });
    // get products data
    axios.get(productsURL).then((response) => setProducts(response.data));
  }, []);

  return (
    <footer className={`${isArabic && "arabic"}`}>
      <div className="info ">
        <div className="logo">
          <img src={require("../../Assets/Images/logos2.png")} alt="" />
        </div>
        <div className="links-container ">
          <ul className="solutions-links links">
            <h4 className="links-heading">Solutions</h4>
            {solutions.map((solution) => {
              return (
                <li>
                  <Link to="/solutions" className="link">
                    {isArabic ? solution.arabic_name : solution.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          <ul className="products-links links">
            <h4 className="links-heading">Products</h4>

            {products.map((product) => {
              return (
                <li>
                  <Link to="/products" className="link">
                    {isArabic ? product.arabic_name : product.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          <ul className="quick-links links">
            <h4 className="links-heading">Quick Links</h4>
            <li>
              <Link to="/about" className="link">
                {isArabic ? 'الشهادات' : "Certificates"}
              </Link>
            </li>
            <li>
              <Link to="/about" className="link">
                {isArabic ? 'عن الشركة' : "About Us"}
              </Link>
            </li>
            <li>
              <Link to="/Products" className="link">
                {isArabic ? 'المنتجات' : "Products"}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="license ">
        <div className="icons">
          <div className="icon">
            <a href={linkedin_link}>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </div>
          <div className="icon">
            <a href={facebook_link}>
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </div>
          <div className="icon">
            <a href={twitter_link}>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
          <div className="icon">
            <a href={`tel:${phone_number}`}>
              <FontAwesomeIcon icon={faPhone} />
            </a>
          </div>
          <div className="icon">
            <a href={`mailto: ${email}`}>
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
          <div className="icon fs-5">
            <a href={whatsapp_link} target="_blank">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
        </div>
        <p className="copyright">
          &copy; 2022 IMT, Produced By{" "}
          <a
            href="https://teryaq.media"
            className="text-white text-decoration-none"
            target="_blank"
          >
            Teryaq
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
