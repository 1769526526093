import React from "react";
import Footer from "../../Components/Footer/Footer";
import "./Products.scss";
import Landing from "./Sections/Landing/Landing";
import ProductsSec from "./Sections/ProductsSec/ProductsSec";

let Products = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="products">
      <Landing />
      <ProductsSec />
      {/* <Footer /> */}
    </div>
  );
};

export default Products;
