import React from 'react'

const PhoneSVG = () => {
  return (
    <svg
                  width="29"
                  height="43"
                  viewBox="0 0 29 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.3393 0H3.07143C1.37734 0 0 1.37734 0 3.07143V39.9286C0 41.6227 1.37734 43 3.07143 43H25.3393C27.0334 43 28.4107 41.6227 28.4107 39.9286V3.07143C28.4107 1.37734 27.0334 0 25.3393 0ZM14.2054 36.5692C13.1448 36.5692 12.2857 35.7102 12.2857 34.6496C12.2857 33.589 13.1448 32.7299 14.2054 32.7299C15.266 32.7299 16.125 33.589 16.125 34.6496C16.125 35.7102 15.266 36.5692 14.2054 36.5692Z"
                    fill="#07074E"
                  />
                </svg>
  )
}

export default PhoneSVG