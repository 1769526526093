import { useContext } from "react";
import { context } from "../../../../App";
import { NewsProps } from "../../../../Assets/typeScript/interfaces";
import "./NewsBody.scss";

const NewsBody: React.FC<NewsProps> = ({ desc,arabic_desc, sections }) => {
    let isArabic = useContext(context)?.isArabic
    return (
      <div className="news-body">
        {
          desc &&
          <p className="desc news-desciption mb-5" dangerouslySetInnerHTML={{__html: `${isArabic? arabic_desc : desc}`}} >
          </p>
        }
        {sections && sections.map(({ title, arabic_title, text, arabic_text, image1, image2 }) => {
          return (
            <div className="news-content ">
              <h2 className="news-title">{isArabic? arabic_title : title}</h2>
              <div className={`content-wrapper ${image2 && "flex-column"}`}>
                {text && (
                  <p
                    className="news-text"
                    dangerouslySetInnerHTML={{ __html: `${isArabic? arabic_text : text}` }}
                  ></p>
                )}
                <div
                  className={`images d-flex ${!image2 && "w-100"} ${
                    !image1 && !image2 && "d-none"
                  }`}
                >
                  {image1 && (
                    <img
                      src={`${image1}`}
                      className={`news-image ${!image2 && "mw-100 ms-4"}`}
                      alt="image"
                    />
                  )}
                  {image2 && (
                    <img
                      src={`${image2}`}
                      className="news-image ms-4"
                      alt="image"
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  
};

export default NewsBody;
