import axios from "axios";
import React, { useContext } from "react";
import { context } from "../../../../App";
import HeadingWhiteSVG from "../../../../Assets/Svg/HeadingWhiteSVG";
import { AboutUs, Certificate as CertificateProps } from "../../../../Assets/typeScript/interfaces";
import "./Certificate.scss";

let Certificate = () => {
  let isArabic = useContext(context)?.isArabic


  React.useEffect(() => {
    // Declare Variables
    let pressed: boolean = false;
    let slider: HTMLElement = document.querySelector(".certificates") as HTMLElement;
    let container: HTMLElement = document.querySelector(".certificates-container") as HTMLElement;
    let startX: number;
    // move slider when mouse is clicked
    slider?.addEventListener("mousedown", function (e) {
      // e.preventDefault();
      pressed = true;
      if (e instanceof MouseEvent) {
        startX = e.offsetX - container.offsetLeft;
        slider.addEventListener("mousemove", (e) => {
          if (!pressed) return;
          e.preventDefault();
          // move slider :
          let x = e.offsetX;
          container.style.left = `${x - startX}px`;
          
          // check boundries:
            // if reached (left)
          if (parseInt(container.style.left) > 0) {
            container.style.left = "0px";
          }
            // if reached (right)
          else if (parseInt(container.style.left) < -900) {
            container.style.left = `-900px`;
          }
        });
      }
    });
    window.addEventListener("mouseup", () => {
      pressed = false;
    });
  }, []);


  let [certificates, setCertificates] = React.useState<CertificateProps[]>([])
  const certificatesUrl = `${useContext(context)?.URL}/certificates/`
  // const certificatesUrl = `http://127.0.0.1:8000/api/certificates/`
  React.useEffect(() => {
    axios.get(certificatesUrl)
    .then(response => setCertificates(response.data))
  }, [])



  // Show Certificate Funciton
  let [img, setImage] = React.useState('')

  function showCertificate(e: EventTarget) {
    if (e instanceof HTMLElement) {
      let img = e.firstChild as HTMLImageElement;
      setImage(img.src)
    }
  }




  return (
    <div className="certificates ">
      <div className="heading d-flex justify-content-center" style={isArabic? {direction: 'rtl'}: {}}>
        <p className="gap-5">
          <HeadingWhiteSVG />
          <span>{isArabic ? 'الشهادات' : 'Certificates'}</span>
        </p>
      </div>
      <div className="certificates-container">
        {
          certificates.map (certificate => {
            return (
              <div className="certificate" onClick={(e) => {showCertificate(e.currentTarget)}}  >
          <img src={certificate.image} alt="Certificate1" className="" />
          <p className="certificate-name">
          {isArabic? certificate.arabic_name : certificate.name}
          </p>
          </div>
            )
          })
        }
      </div>
      {
        img && <div className="certificate-preview" onClick={() => {setImage('')}}>
        <img src={img} alt="CertificatePreview"/>
        </div>
      }
      
    </div>
  );
};

export default Certificate;
