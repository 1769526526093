import "./ProductSec.scss";
import React, { useContext } from "react";
// Component Imports
import ProductsPreview from "../ProductsPreview/ProductsPreview";
import axios from "axios";
import ProductBox from "../../../../Components/ProductBox/ProductBox";
import { Product, SubProduct } from "../../../../Assets/typeScript/interfaces";
import { context } from "../../../../App";
import { products } from "../../../../Assets/Data/Data";

const ProductsSec = () => {
  let isArabic = useContext(context)?.isArabic;
  // Remote Data
  const productsURL = `${useContext(context)?.URL}/products/`;
  const subProductsURL = `${useContext(context)?.URL}/sub_products/`;

  const [productsData, setProductsData] = React.useState<Product[]>([]);

  React.useEffect(() => {
    axios.get(subProductsURL).then((sub_products) => {
      axios.get(productsURL).then((products) => {
        let Data = products.data
          .filter((product: any) => product.active)
          .map((product: Product) => {
            return {
              ...product,
              sub_product: sub_products.data.filter((sub: SubProduct) => sub.product === product.id),
            };
          });
        setProductsData(Data);
      });
    });
  }, []);

  // show Product Preview Section (Phone)
  let [activeNum, setActiveNum] = React.useState(-1);
  // show Product Preview Section (Desktop)
  let [activeData, setActiveData] = React.useState<SubProduct[]>([]);
  // Products preview Row
  let [row, setRow] = React.useState(0);

  // Show Products / Activate Product Function:
  function showProducts(e: EventTarget, id: number, i: number) {
    let col;
    if (e instanceof HTMLElement) {
      col = e.parentElement?.parentElement;
    }
    let products = document.querySelectorAll(".products-container .product");
    // Remove Active class from all products
    for (let i = 0; i < products.length; i++) {
      products[i].classList.remove("active");
    }
    // add Class Acitve to pressed product:
    if (col instanceof HTMLElement) {
      col.classList.add("active");
    }
    // show Products Preview
    if (window.innerWidth < 768) {
      let object = productsData.find((prod) => prod.id == id);
      if (object != undefined) {
        let index: number = productsData.indexOf(object);
        setActiveNum(index);
      }
    } else {
      let object = productsData.find((prod) => prod.id == id);
      if (object != undefined) {
        let index: number = productsData.indexOf(object);
      }

      i <= 2 ? setRow(1) : i <= 5 ? setRow(2) : i <= 8 ? setRow(3) : setRow(4);
      if (object != undefined) {
        setActiveData(object.sub_product);
      }
    }
    // scroll to show sub_products
    setTimeout(() => {
      let productsPreview = document.querySelector(".products-preview") as HTMLElement;
      // console.log(productsPreview.offsetTop);
      window.scrollTo(0, productsPreview.offsetTop + 800);
    }, 250);
  }
  return (
    <div className="products-section">
      <div className="section-info" style={isArabic ? { direction: "rtl" } : {}}>
        <p className="heading">{isArabic ? "المنتجات" : "Products"}</p>
      </div>
      <div className="products-container overflow-hidden">
        {window.innerWidth < 768 &&
          productsData.map((product, i) => {
            let object = productsData.find((prod) => prod.id == product.id);
            let index: number = 0;
            if (object != undefined) {
              index = productsData.indexOf(object);
            }
            let sub_product: SubProduct[] = productsData[index].sub_product;
            return (
              <>
                <ProductBox {...product} showProducts={showProducts} i={i} key={product.id} />
                {activeNum == index && <ProductsPreview {...sub_product} />}
              </>
            );
          })}

        {window.innerWidth > 768 && (
          <>
            <div className="products-group">
              {productsData.map((product, i) => {
                if (i > 2) return;
                return <ProductBox {...product} showProducts={showProducts} i={i} key={product.id} />;
              })}
            </div>
            {row == 1 && <ProductsPreview {...activeData} />}
            {productsData.length > 3 && (
              <div className="products-group">
                {productsData.map((product, i) => {
                  if (i <= 2 || i > 5) return;
                  return <ProductBox {...product} showProducts={showProducts} i={i} key={product.id} />;
                })}
              </div>
            )}
            {row == 2 && <ProductsPreview {...activeData} />}

            {productsData.length > 6 && (
              <div className="products-group">
                {productsData.map((product, i) => {
                  if (i <= 5 || i > 8) return;
                  return <ProductBox {...product} showProducts={showProducts} i={i} key={product.id} />;
                })}
              </div>
            )}
            {row == 3 && <ProductsPreview {...activeData} />}

            {productsData.length > 9 && (
              <div className="products-group">
                {productsData.map((product, i) => {
                  if (i <= 8 || i > 11) return;
                  return <ProductBox {...product} showProducts={showProducts} i={i} key={product.id} />;
                })}
              </div>
            )}
            {row == 4 && <ProductsPreview {...activeData} />}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsSec;
