import React, { useContext } from "react";
import HeadingSVG from "../../../../Assets/Svg/HeadingSVG";
import "./Clients.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
// interface import
import { Client } from "../../../../Assets/typeScript/interfaces";
import { context } from "../../../../App";


const Clients = () => {
  let isArabic = useContext(context)?.isArabic


// Remote Data
const clientsUrl = `${useContext(context)?.URL}/clients/`
const [clients, setClients] = React.useState<Client[]>([])
React.useEffect(() => {
  axios.get(clientsUrl)
  .then(response => setClients(response.data))
}, [])



  const slideSlick = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 2500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    selectedTab: false,
    
    responsive: [
      
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};
      
  
  

  return (
    <div className="clients">
      <div className="section-info d-flex justify-content-center">
     
        <p className="heading" style={isArabic? {direction: 'rtl', gap:'1rem'}: {}}>
          <HeadingSVG />
          {isArabic ? "عملائنا" : "Our Happy Clients"}
        </p>
      </div>
      
      <Slider className="rn-slick-dot dot-light" {...slideSlick}>
        
          {
            clients && 
            clients.map((client, index: number) => (
              <div key={index} className="client-image">
                <img
                  src={client.logo}
                  alt={client.name}
                />
              </div>
            ))
          }
        </Slider>
          
          

      </div>
    // </div>
  );
};

export default Clients;
