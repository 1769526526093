import "./Home.scss";
// Componenets Import
import Header from "../../Components/Header/Header";
import HomeLanding from "./Sections/HomeLanding/HomeLanding";
import Solutions from "./Sections/Solutions/Solutions";
import Testimonials from "./Sections/Testimonials/Testimonials";
import Clients from "./Sections/Clients/Clients";
import Contact from './Sections/Contact/Contact'
import Footer from "../../Components/Footer/Footer";
import React, { useContext } from "react";
import SentNotification from "../../Components/SentNotification/SentNotification";
import axios from "axios";
import { TestimonialProps, WebsiteData } from "../../Assets/typeScript/interfaces";
import { context } from "../../App";
import LatestNews from "./Sections/LatestNews/LatestNews";

let Home = () => {


  React.useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  // render testimonial conditionally
    // Remote Testimonial Data
  
  const testimonialURL = `${useContext(context)?.URL}/testimonial/`
  const [testimonials, setTestimonials] = React.useState<TestimonialProps[]>([])

  React.useEffect(() => {
    axios.get(testimonialURL) 
    .then(response => setTestimonials(response.data))
  }, [])


  // Home Data
  const websiteDataURL = `${useContext(context)?.URL}/website_data/`
  const [data, setData] = React.useState<WebsiteData | null>(null)
  React.useEffect(() => {
    axios.get(websiteDataURL)
    .then(response => setData(response.data))
  }, [])
  
  // Show Notification
  let [shown, setShown] = React.useState(false)

  return (
    <div className="home">
      {shown && <SentNotification />}
      <HomeLanding {...data} />
      <Solutions {...data} />
      {testimonials.length != 0 && <Testimonials {...testimonials} />}
      {/* <Testimonials {...testimonials} /> */}
      <Clients />
      <Contact setShown={setShown} />
      <LatestNews />
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
