import './ProductsPreview.scss'
import React, { useContext } from 'react'
import { SubProduct } from '../../../../Assets/typeScript/interfaces';
import { context } from '../../../../App';
import Slider from 'react-slick';



const ProductsPreview:React.FC<SubProduct[]> = (sub_products: SubProduct[]) => {
  let isArabic = useContext(context)?.isArabic
  let products = Object.values(sub_products)

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1500,
  cssEase: 'ease-in-out',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
  
};

  return (
    <div className="products-preview ">
        <div className="products-slider">
          <Slider 
          {...settings}
          onSwipe={() => {

          }}
          >
          {
            products.map(({id, name,arabic_name, image, brochure, product}) => {
              return(
                <div className="product" key={id}>
                  <img src={image} alt={name} />
                  <div className="info">
                  <div className="product-name">{isArabic? arabic_name: name}</div>
                  <a href={`/product/${product}`} className="download text-decoration-none" target='blank_'>See More...</a>
                  </div>
                </div>
              )
            })
          }
          {
            products.length < 3 && 
            <>
            </>
          }
          </Slider>
          
          {/* <div className="product">
            <img src={require('../../../../Assets/Images/Products/audio1.avif')} alt="Product1" />
            <div className="info">
            <div className="product-name">Product 01</div>
            <div className="download">Download Brochure</div>
            </div>
          </div>
          <div className="product">
            <img src={require('../../../../Assets/Images/Products/audio2.avif')} alt="Product2" />
            <div className="info">
            <div className="product-name">Product 01</div>
            <div className="download">Download Brochure</div>
            </div>
          </div>
          <div className="product">
            <img src={require('../../../../Assets/Images/Products/audio3.avif')} alt="Product3" />
            <div className="info">
            <div className="product-name">Product 01</div>
            <div className="download">Download Brochure</div>
            </div>
          </div>
          <div className="product">
            <img src={require('../../../../Assets/Images/Products/audio4.avif')} alt="Product4" />
            <div className="info">
            <div className="product-name">Product 01</div>
            <div className="download">Download Brochure</div>
            </div>
          </div> */}
        </div>
      </div>
  )
}

export default ProductsPreview