import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { context } from "../../../../App";
import HeadingSVG from "../../../../Assets/Svg/HeadingSVG";
import { WebsiteData } from "../../../../Assets/typeScript/interfaces";
import "./Solutions.scss";

let Solutions: React.FC<WebsiteData> = ({ solution_desc, arabic_solution_desc }) => {
  let isArabic = useContext(context)?.isArabic;
  

  let navigate = useNavigate();

  // calculate max scrollable width
  let maxWidth: number;

  // remote data
  const solutionsData = `${useContext(context)?.URL}/home_solutions/`;
  const [solutions, setSolutions] = React.useState([]);

  React.useEffect(() => {
    axios.get(solutionsData).then((response) => {
      setSolutions(response.data);

      window.innerWidth > 992
        ? (maxWidth = (response.data.length - 2) * 250)
        : window.innerWidth > 768
        ? (maxWidth = response.data.length * 250 + 300)
        : (maxWidth = 0);
    });
  }, []);

  // Solution Slider Function:
  //
  let [count, setCount] = React.useState(1);
  let [disabled, setDisabled] = React.useState(false);
// alert(count)


  function slide(dir: string) {
    // Decalre Variable
    let container = document.querySelector(
      ".solutions-container"
    ) as HTMLElement;
    let left = parseFloat(getComputedStyle(container).left);
    let width = document.querySelector(".solution-box")?.clientWidth;

    // If Boundry reached, return
    if (count == solutions.length - 1) {
      if (dir == "next") {
        if (width) {
          container.style.left = `${
            0
          }rem`;
        }
        setCount(1)
        return;
      }
    }
    if (count == 1) {
      if (dir == "prev") {
        return;
      }
    }

    // Disable Slide Button:
    setDisabled(true);

    // Slide According to slide direction:
    
      if (width)
        if (dir == "next") {
         
          
          if (width)
            container.style.left = `${
              isArabic
                ? left / 16 + width / 16 + 1.6
                : left / 16 - width / 16 - 1.6
            }rem`;
          setCount((prevCount) => prevCount + 1);
        } else if (dir == "prev") {
          if (width)
            container.style.left = `${
              isArabic
                ? left / 16 - width / 16 - 1.6
                : left / 16 + width / 16 + 1.6
            }rem`;
          setCount((prevCount) => prevCount - 1);
        }
        

    // Activate Button:
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  }

  // AutoPlay Logic
  let [autoPlay, setAutoPlay] = useState(true)
  let [slides, setSlides] = useState(0)
  React.useEffect(() => {
    if (window.innerWidth > 768) {
      setTimeout(() => {
        if (autoPlay) {
          slide('next')
          setSlides(prev => prev+1)

        }
      }, 2000)
    }
    
  }, [slides])
  // Grabbable Slider Effect

  React.useEffect(() => {
    // Declare Variables
    let pressed: boolean = false;
    let slider: HTMLElement = document.querySelector(
      ".solutions-slider"
    ) as HTMLElement;
    let container: HTMLElement = document.querySelector(
      ".solutions-container"
    ) as HTMLElement;
    let startX: number;
    // move slider when mouse is clicked & moving
    slider?.addEventListener("mousedown", function (e) {
      let solutionsLength = document.querySelectorAll('.solution-box').length

      e.preventDefault();
      setAutoPlay(false)
      pressed = true;
      // Remove auto sliding
      container.style.transitionTimingFunction = "ease-out";
      if (e instanceof MouseEvent) {
        startX = e.offsetX - container.offsetLeft;
        slider.addEventListener("mousemove", (e) => {
          if (!pressed) return;
          e.preventDefault();
          // move slider :
          let x = e.offsetX;
          container.style.left = `${x - startX}px`;

          // check boundries:

          // if reached (left)
          if (parseInt(container.style.left) > (isArabic ? maxWidth : 0)) {
            container.style.left = isArabic ? `${maxWidth}px` : "0px";
            setCount(isArabic? solutionsLength -1  : 1)
          }
          // if reached (right)
          else if (
            parseInt(container.style.left) < (isArabic ? 0 : -maxWidth)
          ) {
            container.style.left = isArabic ? "0px" : `-${maxWidth}px`;
            setCount(isArabic? 1 : solutionsLength - 1)
          }
        });
      }
    });
    // Disable sliding when mouse button isn't clicked
    window.addEventListener("mouseup", () => {
      pressed = false;
    });
  }, []);

  return (
    <div
      className={`solutions py-5  ${isArabic ? 'arabic' : ''}`}
     
    >
      <div
        className="section-info"
        style={isArabic ? { direction: "rtl" } : {}}
      >
        <p
          className={`heading gap-3`}
          style={isArabic ? { direction: "rtl" } : {}}
        >
          <HeadingSVG />
          {isArabic ? "الحلول" : "Solutions"}
        </p>
        <p className="section-desc">
          {isArabic? arabic_solution_desc: solution_desc}
        </p>
        <div
          className="slider-btns"
        >
          <button
            className="slider-btn"
            disabled={disabled}
            onClick={() => {slide("prev"); setAutoPlay(false)}}
          >
            <svg
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.8837 14.1309H4.88518L16.8549 24.5215C17.0463 24.689 16.9301 25 16.6772 25H13.6523C13.519 25 13.3925 24.9521 13.2934 24.8667L0.376876 13.6592C0.258576 13.5566 0.1637 13.4299 0.098676 13.2874C0.0336522 13.145 0 12.9903 0 12.8337C0 12.6772 0.0336522 12.5225 0.098676 12.38C0.1637 12.2376 0.258576 12.1108 0.376876 12.0083L13.3686 0.732422C13.4198 0.687988 13.4814 0.664062 13.5463 0.664062H16.6737C16.9267 0.664062 17.0429 0.978516 16.8515 1.14258L4.88518 11.5332H24.8837C25.0341 11.5332 25.1571 11.6562 25.1571 11.8066V13.8574C25.1571 14.0078 25.0341 14.1309 24.8837 14.1309Z"
                fill="url(#paint0_linear_9_215)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_9_215"
                  x1="-13"
                  y1="0.664063"
                  x2="44"
                  y2="0.664063"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.0138042" stopColor="#1B3884" />
                  <stop offset="1" stopColor="#5DC2E9" />
                </linearGradient>
              </defs>
            </svg>
          </button>
          <button
            className="slider-btn"
            disabled={disabled}
            onClick={() => {slide("next"); setAutoPlay(false)}}
          >
            <svg
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.273425 10.8691L20.272 10.8691L8.30223 0.478514C8.11083 0.311034 8.22704 -1.48008e-06 8.47997 -1.45796e-06L11.5049 -1.19352e-06C11.6382 -1.18187e-06 11.7646 0.0478502 11.8638 0.133299L24.7803 11.3408C24.8986 11.4434 24.9934 11.5701 25.0585 11.7126C25.1235 11.855 25.1571 12.0097 25.1571 12.1663C25.1571 12.3228 25.1235 12.4775 25.0585 12.62C24.9934 12.7624 24.8986 12.8892 24.7803 12.9917L11.7886 24.2676C11.7373 24.312 11.6758 24.3359 11.6108 24.3359L8.48338 24.3359C8.23046 24.3359 8.11424 24.0215 8.30565 23.8574L20.272 13.4668L0.273425 13.4668C0.123034 13.4668 -1.26106e-05 13.3437 -1.25975e-05 13.1934L-1.24182e-05 11.1426C-1.24051e-05 10.9922 0.123034 10.8691 0.273425 10.8691Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="solutions-slider">
        <div className="bg-shape"></div>
        <div
          className="solutions-container d-flex"
        >
          {solutions.map(({ id, name,arabic_name, home_image }) => {
            return (
              <div className={`solution-box ${isArabic && "arabic"}`} key={id}>
                <div className="background-overlay"></div>
                <img src={home_image} alt={name} />
                <p className="solution-name">{isArabic? arabic_name: name}</p>
                <button
                  className="show-btn"
                  onClick={() => {
                    navigate("/solutions");
                  }}
                >
                  <svg
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5 1.125C22.5 0.826631 22.3815 0.540483 22.1705 0.329505C21.9595 0.118526 21.6734 0 21.375 0C21.0766 0 20.7905 0.118526 20.5795 0.329505C20.3685 0.540483 20.25 0.826631 20.25 1.125V16.875C20.25 17.1734 20.3685 17.4595 20.5795 17.6705C20.7905 17.8815 21.0766 18 21.375 18C21.6734 18 21.9595 17.8815 22.1705 17.6705C22.3815 17.4595 22.5 17.1734 22.5 16.875V1.125ZM9.795 0.33C9.58174 0.13128 9.29967 0.0230953 9.00822 0.0282376C8.71677 0.0333799 8.43869 0.151448 8.23257 0.357567C8.02645 0.563687 7.90838 0.841764 7.90324 1.13322C7.8981 1.42467 8.00628 1.70674 8.205 1.92L14.16 7.875H1.125C0.826631 7.875 0.540483 7.99353 0.329505 8.20451C0.118526 8.41548 0 8.70163 0 9C0 9.29837 0.118526 9.58452 0.329505 9.79549C0.540483 10.0065 0.826631 10.125 1.125 10.125H14.16L8.205 16.08C8.09447 16.183 8.00582 16.3072 7.94433 16.4452C7.88284 16.5832 7.84978 16.7322 7.84711 16.8832C7.84445 17.0343 7.87223 17.1843 7.92882 17.3244C7.9854 17.4645 8.06961 17.5917 8.17644 17.6986C8.28327 17.8054 8.41052 17.8896 8.5506 17.9462C8.69069 18.0028 8.84073 18.0306 8.99178 18.0279C9.14284 18.0252 9.29181 17.9922 9.42981 17.9307C9.56781 17.8692 9.69201 17.7805 9.795 17.67L17.67 9.795C17.8807 9.58406 17.999 9.29813 17.999 9C17.999 8.70187 17.8807 8.41594 17.67 8.205L9.795 0.33Z"
                      fill="#1238FF"
                    />
                  </svg>
                </button>
              </div>
            );
          })}

          {/* <div className="solution-box">
            <div className="background-overlay"></div>
            <img
              src={require("../../../../Assets/Images/Solutions/Home/CityWide.avif")}
              alt="Audio Solutions"
            />
            <p className="solution-name">
            Smart City Wide Infrastructure
            </p>
            <button className="show-btn" onClick={() => {navigate("/solutions")}}>
              <svg
                width="23"
                height="19"
                viewBox="0 0 23 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.125C22.5 0.826631 22.3815 0.540483 22.1705 0.329505C21.9595 0.118526 21.6734 0 21.375 0C21.0766 0 20.7905 0.118526 20.5795 0.329505C20.3685 0.540483 20.25 0.826631 20.25 1.125V16.875C20.25 17.1734 20.3685 17.4595 20.5795 17.6705C20.7905 17.8815 21.0766 18 21.375 18C21.6734 18 21.9595 17.8815 22.1705 17.6705C22.3815 17.4595 22.5 17.1734 22.5 16.875V1.125ZM9.795 0.33C9.58174 0.13128 9.29967 0.0230953 9.00822 0.0282376C8.71677 0.0333799 8.43869 0.151448 8.23257 0.357567C8.02645 0.563687 7.90838 0.841764 7.90324 1.13322C7.8981 1.42467 8.00628 1.70674 8.205 1.92L14.16 7.875H1.125C0.826631 7.875 0.540483 7.99353 0.329505 8.20451C0.118526 8.41548 0 8.70163 0 9C0 9.29837 0.118526 9.58452 0.329505 9.79549C0.540483 10.0065 0.826631 10.125 1.125 10.125H14.16L8.205 16.08C8.09447 16.183 8.00582 16.3072 7.94433 16.4452C7.88284 16.5832 7.84978 16.7322 7.84711 16.8832C7.84445 17.0343 7.87223 17.1843 7.92882 17.3244C7.9854 17.4645 8.06961 17.5917 8.17644 17.6986C8.28327 17.8054 8.41052 17.8896 8.5506 17.9462C8.69069 18.0028 8.84073 18.0306 8.99178 18.0279C9.14284 18.0252 9.29181 17.9922 9.42981 17.9307C9.56781 17.8692 9.69201 17.7805 9.795 17.67L17.67 9.795C17.8807 9.58406 17.999 9.29813 17.999 9C17.999 8.70187 17.8807 8.41594 17.67 8.205L9.795 0.33Z"
                  fill="#1238FF"
                />
              </svg>
            </button>
          </div>
          <div className="solution-box">
            <div className="background-overlay"></div>
            <img
              src={require("../../../../Assets/Images/Solutions/Home/Video.avif")}
              alt="Audio - Video Solutions"
            />
            <p className="solution-name">Video Solutions</p>
            <button className="show-btn" onClick={() => {navigate("/solutions")}}>
              <svg
                width="23"
                height="19"
                viewBox="0 0 23 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.125C22.5 0.826631 22.3815 0.540483 22.1705 0.329505C21.9595 0.118526 21.6734 0 21.375 0C21.0766 0 20.7905 0.118526 20.5795 0.329505C20.3685 0.540483 20.25 0.826631 20.25 1.125V16.875C20.25 17.1734 20.3685 17.4595 20.5795 17.6705C20.7905 17.8815 21.0766 18 21.375 18C21.6734 18 21.9595 17.8815 22.1705 17.6705C22.3815 17.4595 22.5 17.1734 22.5 16.875V1.125ZM9.795 0.33C9.58174 0.13128 9.29967 0.0230953 9.00822 0.0282376C8.71677 0.0333799 8.43869 0.151448 8.23257 0.357567C8.02645 0.563687 7.90838 0.841764 7.90324 1.13322C7.8981 1.42467 8.00628 1.70674 8.205 1.92L14.16 7.875H1.125C0.826631 7.875 0.540483 7.99353 0.329505 8.20451C0.118526 8.41548 0 8.70163 0 9C0 9.29837 0.118526 9.58452 0.329505 9.79549C0.540483 10.0065 0.826631 10.125 1.125 10.125H14.16L8.205 16.08C8.09447 16.183 8.00582 16.3072 7.94433 16.4452C7.88284 16.5832 7.84978 16.7322 7.84711 16.8832C7.84445 17.0343 7.87223 17.1843 7.92882 17.3244C7.9854 17.4645 8.06961 17.5917 8.17644 17.6986C8.28327 17.8054 8.41052 17.8896 8.5506 17.9462C8.69069 18.0028 8.84073 18.0306 8.99178 18.0279C9.14284 18.0252 9.29181 17.9922 9.42981 17.9307C9.56781 17.8692 9.69201 17.7805 9.795 17.67L17.67 9.795C17.8807 9.58406 17.999 9.29813 17.999 9C17.999 8.70187 17.8807 8.41594 17.67 8.205L9.795 0.33Z"
                  fill="#1238FF"
                />
              </svg>
            </button>
          </div>
          <div className="solution-box">
            <div className="background-overlay"></div>
            <img
              src={require("../../../../Assets/Images/Solutions/Home/Conferences.avif")}
              alt="Audio - Video Solutions"
            />
            <p className="solution-name">Conference &amp; Discussion systems</p>
            <button className="show-btn" onClick={() => {navigate("/solutions")}}>
              <svg
                width="23"
                height="19"
                viewBox="0 0 23 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.125C22.5 0.826631 22.3815 0.540483 22.1705 0.329505C21.9595 0.118526 21.6734 0 21.375 0C21.0766 0 20.7905 0.118526 20.5795 0.329505C20.3685 0.540483 20.25 0.826631 20.25 1.125V16.875C20.25 17.1734 20.3685 17.4595 20.5795 17.6705C20.7905 17.8815 21.0766 18 21.375 18C21.6734 18 21.9595 17.8815 22.1705 17.6705C22.3815 17.4595 22.5 17.1734 22.5 16.875V1.125ZM9.795 0.33C9.58174 0.13128 9.29967 0.0230953 9.00822 0.0282376C8.71677 0.0333799 8.43869 0.151448 8.23257 0.357567C8.02645 0.563687 7.90838 0.841764 7.90324 1.13322C7.8981 1.42467 8.00628 1.70674 8.205 1.92L14.16 7.875H1.125C0.826631 7.875 0.540483 7.99353 0.329505 8.20451C0.118526 8.41548 0 8.70163 0 9C0 9.29837 0.118526 9.58452 0.329505 9.79549C0.540483 10.0065 0.826631 10.125 1.125 10.125H14.16L8.205 16.08C8.09447 16.183 8.00582 16.3072 7.94433 16.4452C7.88284 16.5832 7.84978 16.7322 7.84711 16.8832C7.84445 17.0343 7.87223 17.1843 7.92882 17.3244C7.9854 17.4645 8.06961 17.5917 8.17644 17.6986C8.28327 17.8054 8.41052 17.8896 8.5506 17.9462C8.69069 18.0028 8.84073 18.0306 8.99178 18.0279C9.14284 18.0252 9.29181 17.9922 9.42981 17.9307C9.56781 17.8692 9.69201 17.7805 9.795 17.67L17.67 9.795C17.8807 9.58406 17.999 9.29813 17.999 9C17.999 8.70187 17.8807 8.41594 17.67 8.205L9.795 0.33Z"
                  fill="#1238FF"
                />
              </svg>
            </button>
          </div>
          <div className="solution-box">
            <div className="background-overlay"></div>
            <img
              src={require("../../../../Assets/Images/Solutions/Home/Command.avif")}
              alt="Audio - Video Solutions"
            />
            <p className="solution-name">Command and Control Centers Solutions</p>
            <button className="show-btn" onClick={() => {navigate("/solutions")}}>
              <svg
                width="23"
                height="19"
                viewBox="0 0 23 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.125C22.5 0.826631 22.3815 0.540483 22.1705 0.329505C21.9595 0.118526 21.6734 0 21.375 0C21.0766 0 20.7905 0.118526 20.5795 0.329505C20.3685 0.540483 20.25 0.826631 20.25 1.125V16.875C20.25 17.1734 20.3685 17.4595 20.5795 17.6705C20.7905 17.8815 21.0766 18 21.375 18C21.6734 18 21.9595 17.8815 22.1705 17.6705C22.3815 17.4595 22.5 17.1734 22.5 16.875V1.125ZM9.795 0.33C9.58174 0.13128 9.29967 0.0230953 9.00822 0.0282376C8.71677 0.0333799 8.43869 0.151448 8.23257 0.357567C8.02645 0.563687 7.90838 0.841764 7.90324 1.13322C7.8981 1.42467 8.00628 1.70674 8.205 1.92L14.16 7.875H1.125C0.826631 7.875 0.540483 7.99353 0.329505 8.20451C0.118526 8.41548 0 8.70163 0 9C0 9.29837 0.118526 9.58452 0.329505 9.79549C0.540483 10.0065 0.826631 10.125 1.125 10.125H14.16L8.205 16.08C8.09447 16.183 8.00582 16.3072 7.94433 16.4452C7.88284 16.5832 7.84978 16.7322 7.84711 16.8832C7.84445 17.0343 7.87223 17.1843 7.92882 17.3244C7.9854 17.4645 8.06961 17.5917 8.17644 17.6986C8.28327 17.8054 8.41052 17.8896 8.5506 17.9462C8.69069 18.0028 8.84073 18.0306 8.99178 18.0279C9.14284 18.0252 9.29181 17.9922 9.42981 17.9307C9.56781 17.8692 9.69201 17.7805 9.795 17.67L17.67 9.795C17.8807 9.58406 17.999 9.29813 17.999 9C17.999 8.70187 17.8807 8.41594 17.67 8.205L9.795 0.33Z"
                  fill="#1238FF"
                />
              </svg>
            </button>
          </div>
          <div className="solution-box">
            <div className="background-overlay"></div>
            <img
              src={require("../../../../Assets/Images/Solutions/Home/Audio.avif")}
              alt="Audio - Video Solutions"
            />
            <p className="solution-name">
              Audio Solutions
            </p>
            <button className="show-btn" onClick={() => {navigate("/solutions")}}>
              <svg
                width="23"
                height="19"
                viewBox="0 0 23 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.125C22.5 0.826631 22.3815 0.540483 22.1705 0.329505C21.9595 0.118526 21.6734 0 21.375 0C21.0766 0 20.7905 0.118526 20.5795 0.329505C20.3685 0.540483 20.25 0.826631 20.25 1.125V16.875C20.25 17.1734 20.3685 17.4595 20.5795 17.6705C20.7905 17.8815 21.0766 18 21.375 18C21.6734 18 21.9595 17.8815 22.1705 17.6705C22.3815 17.4595 22.5 17.1734 22.5 16.875V1.125ZM9.795 0.33C9.58174 0.13128 9.29967 0.0230953 9.00822 0.0282376C8.71677 0.0333799 8.43869 0.151448 8.23257 0.357567C8.02645 0.563687 7.90838 0.841764 7.90324 1.13322C7.8981 1.42467 8.00628 1.70674 8.205 1.92L14.16 7.875H1.125C0.826631 7.875 0.540483 7.99353 0.329505 8.20451C0.118526 8.41548 0 8.70163 0 9C0 9.29837 0.118526 9.58452 0.329505 9.79549C0.540483 10.0065 0.826631 10.125 1.125 10.125H14.16L8.205 16.08C8.09447 16.183 8.00582 16.3072 7.94433 16.4452C7.88284 16.5832 7.84978 16.7322 7.84711 16.8832C7.84445 17.0343 7.87223 17.1843 7.92882 17.3244C7.9854 17.4645 8.06961 17.5917 8.17644 17.6986C8.28327 17.8054 8.41052 17.8896 8.5506 17.9462C8.69069 18.0028 8.84073 18.0306 8.99178 18.0279C9.14284 18.0252 9.29181 17.9922 9.42981 17.9307C9.56781 17.8692 9.69201 17.7805 9.795 17.67L17.67 9.795C17.8807 9.58406 17.999 9.29813 17.999 9C17.999 8.70187 17.8807 8.41594 17.67 8.205L9.795 0.33Z"
                  fill="#1238FF"
                />
              </svg>
            </button>
          </div>
          <div className="solution-box">
            <div className="background-overlay"></div>
            <img
              src={require("../../../../Assets/Images/Solutions/Home/LowCurrent.avif")}
              alt="Audio - Video Solutions"
            />
            <p className="solution-name">Low Current , Wireless and IT Infrastructure</p>
            <button className="show-btn" onClick={() => {navigate("/solutions")}}>
              <svg
                width="23"
                height="19"
                viewBox="0 0 23 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.125C22.5 0.826631 22.3815 0.540483 22.1705 0.329505C21.9595 0.118526 21.6734 0 21.375 0C21.0766 0 20.7905 0.118526 20.5795 0.329505C20.3685 0.540483 20.25 0.826631 20.25 1.125V16.875C20.25 17.1734 20.3685 17.4595 20.5795 17.6705C20.7905 17.8815 21.0766 18 21.375 18C21.6734 18 21.9595 17.8815 22.1705 17.6705C22.3815 17.4595 22.5 17.1734 22.5 16.875V1.125ZM9.795 0.33C9.58174 0.13128 9.29967 0.0230953 9.00822 0.0282376C8.71677 0.0333799 8.43869 0.151448 8.23257 0.357567C8.02645 0.563687 7.90838 0.841764 7.90324 1.13322C7.8981 1.42467 8.00628 1.70674 8.205 1.92L14.16 7.875H1.125C0.826631 7.875 0.540483 7.99353 0.329505 8.20451C0.118526 8.41548 0 8.70163 0 9C0 9.29837 0.118526 9.58452 0.329505 9.79549C0.540483 10.0065 0.826631 10.125 1.125 10.125H14.16L8.205 16.08C8.09447 16.183 8.00582 16.3072 7.94433 16.4452C7.88284 16.5832 7.84978 16.7322 7.84711 16.8832C7.84445 17.0343 7.87223 17.1843 7.92882 17.3244C7.9854 17.4645 8.06961 17.5917 8.17644 17.6986C8.28327 17.8054 8.41052 17.8896 8.5506 17.9462C8.69069 18.0028 8.84073 18.0306 8.99178 18.0279C9.14284 18.0252 9.29181 17.9922 9.42981 17.9307C9.56781 17.8692 9.69201 17.7805 9.795 17.67L17.67 9.795C17.8807 9.58406 17.999 9.29813 17.999 9C17.999 8.70187 17.8807 8.41594 17.67 8.205L9.795 0.33Z"
                  fill="#1238FF"
                />
              </svg>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Solutions;
