import React, { useContext } from "react";
import ClampLines from "react-clamp-lines";
import { context } from "../../../../App";
import HeadingSVG from "../../../../Assets/Svg/HeadingSVG";
import { AboutUs } from "../../../../Assets/typeScript/interfaces";
import "./CMWord.scss";

let CMWord:React.FC<AboutUs> = ({chairman_name,arabic_chairman_name, chairman_word, arabic_chairman_word, chairman_image}) => {
  let isArabic = useContext(context)?.isArabic
  return (
    <div className={`cm-word ${isArabic && 'arabic'}`}>
      <div className="sections d-flex d-none">
        <span className="section active">CM Word</span>
        <span className="section">Our Vision</span>
        <span className="section">Our Team</span>
        <span className="section">Certificates</span>
      </div>
      <p className="heading">
        <HeadingSVG />
        {isArabic ? 'كلمة المدير العام' : 'Statement of Chairman'}
      </p>
      <div className="content-container d-flex">
        <img
          src={`https://dashboard.imanagement.sa/${chairman_image}`}
          alt="businessMan"
        />
        <div className="word position-relative">
          <div className="quoteMark quoteMarkUp mb-4">
            <svg
              width="71"
              height="52"
              viewBox="0 0 71 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M65.5119 0H50.3938L40.315 20.8V52H70.5513V20.8H55.4332L65.5119 0ZM25.1969 0H10.0788L0 20.8V52H30.2363V20.8H15.1181L25.1969 0Z"
                fill="#5DC2E9"
              />
            </svg>
          </div>
          
          <div className="text"  dangerouslySetInnerHTML={{
							__html: `${isArabic? arabic_chairman_word : chairman_word}`?? '',
						}} >

          </div>
          <div className="quoteMark quoteMarkDown ">
            <svg
              width="71"
              height="52"
              viewBox="0 0 71 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.48808 52L20.6062 52L30.685 31.2L30.685 -3.69674e-06L0.448712 -6.46929e-06L0.448709 31.2L15.5668 31.2L5.48808 52ZM45.8031 52L60.9212 52L71 31.2L71 0L40.7637 -2.77255e-06L40.7637 31.2L55.8819 31.2L45.8031 52Z"
                fill="#5DC2E9"
              />
            </svg>
          </div>
          <p className="name m-0">{isArabic? arabic_chairman_name: chairman_name}</p>
        </div>
      </div>
    </div>
  );
};

export default CMWord;
