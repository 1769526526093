import './NewsPage.scss'
import React, { useContext } from 'react'
import Landing from './Sections/Landing/Landing'
import NewsBody from './Sections/NewsBody/NewsBody'
import { matchPath, useParams } from 'react-router-dom'
import { newsData } from '../../Assets/Data/Data'
import { NewsProps } from '../../Assets/typeScript/interfaces'
import { context } from '../../App'
import axios from 'axios'

const NewsPage = () => {


  const id = useParams().newsId
 // get news Data
 const NewsURL = `${useContext(context)?.URL}/news/`
 const [data, setData] = React.useState<NewsProps>()
 React.useEffect(() => {
   axios.get(NewsURL)
   .then(response => {
    if(typeof id == 'string')
     setData(response.data.find((news: NewsProps) => news.id === parseInt(id)))
   })
 }, [])



  return (
    <div className='news-page'>
      {data && 
      <>
        <Landing {...data}/>
        <NewsBody {...data} />
        </>
      }
    </div>
  )
}

export default NewsPage