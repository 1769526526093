import React from 'react'

const HeadingSVG = () => {
  return (
    <svg
          width="62"
          height="42"
          viewBox="0 0 62 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2273 0L0 21L19.6427 39.558L24.812 34.6741L10.3387 21L22.2273 9.76782L34.116 21L31 23.9439L25.2994 18.558L20.13 23.442L39.7727 42L62 21L39.7727 0L31 8.28827L22.2273 0ZM36.1693 13.1722L44.4547 21L36.1693 28.8278L39.7727 32.2322L51.6613 21L39.7727 9.76782L36.1693 13.1722Z"
            fill="url(#paint0_linear_9_171)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_9_171"
              x1="30.7563"
              y1="7.64682"
              x2="30.7563"
              y2="44.2534"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1B3884" />
              <stop offset="1" stopColor="#1B3884" stopOpacity="0.7" />
            </linearGradient>
          </defs>
        </svg>
  )
}

export default HeadingSVG