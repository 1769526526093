import { useContext } from 'react'
import { context } from '../../App'
import { NewsProps } from '../../Assets/typeScript/interfaces'
import './News.scss'


const News:React.FC<NewsProps> = ({id, title, arabic_title, desc,arabic_desc,  image}: NewsProps) => {
  let isArabic = useContext(context)?.isArabic
  return (
    <div className="news">
      <img src={`${image}`} alt="news" />
      <div className="title">
        {title}
      </div>
      <div className="news-info">
      <p className="news-title"> {isArabic? arabic_title : title} </p>
      {/* <p className="news-desc"> {isArabic? arabic_desc: desc} </p> */}
      <div className='news-desc' dangerouslySetInnerHTML={{__html: isArabic? arabic_desc: desc}} />
      <a href={`/news/${id}`}>
      <button className="see-more">See More</button>
      </a>
      </div>
    </div>
  )
}

export default News