import './Testimonial.scss'
import {TestimonialProps} from '../../Assets/typeScript/interfaces'
import { useContext } from 'react'
import { context } from '../../App'




const Testimonial:React.FC<TestimonialProps>  = ({id, person_name, person_image, testimonial, arabic_testimonial}: TestimonialProps) => {
  let isArabic = useContext(context)?.isArabic
  return (
    <div className="testimonial">
        <p className="text">
          {isArabic? arabic_testimonial: testimonial}
        <img
            src={person_image}
            alt={person_name}
          />
        </p>
      </div>
  )
}

export default Testimonial