import "./ContactForm.scss";
import { useContext, useRef } from "react";
import emailjs from "@emailjs/browser";
import SentNotification from "../SentNotification/SentNotification";
import { context } from "../../App";



const ContactForm = ({setShown}) => {
  let isArabic = useContext(context).isArabic

  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ywo3vc8",
        "template_aofvxuw",
        form.current,
        "z24xgYgqKyZk9rig6"
      )
      .then(
        (result) => {
          setShown(true)
          e.target.reset()
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  return (
    <form ref={form} onSubmit={sendEmail} className={isArabic ? 'arabic' : ''}>
      <input
        type="text"
        className="name-input"
        id="name"
        name="name"
        placeholder={isArabic? 'الأسم': "Name"}
      />
      <input
        type="text"
        className="company-input"
        id="company"
        name="company_name"
        placeholder={isArabic? "اسم الشركة": "Company"}
      />
      <input
        type="email"
        className="email-input"
        id="email"
        name="email"
        placeholder={isArabic? "البريد الالكتروني": "Email"}
      />
      <input
        type="text"
        className="text-input"
        id="text"
        name="text"
        placeholder={isArabic? 'النص': "Text"}
      />
      <textarea
        name="message"
        id="message"
        placeholder={isArabic? "رسالتك": "Your Message"}
        className="pt-3"
      ></textarea>
      <button type="submit" className="submit-btn">{isArabic? "ارسال": "Submit"}</button>
    </form>
  );
};

export default ContactForm;
