import React from 'react'

const BackgroundSVG = () => {
  return (
    <svg width="209" height="206" viewBox="0 0 209 206" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_0_1)">
    <rect x="69.5" y="55.5" width="107" height="114" stroke="url(#paint0_linear_0_1)" strokeWidth="15" shapeRendering="crispEdges"/>
    </g>
    <g filter="url(#filter1_d_0_1)">
    <rect x="146.5" y="28.5" width="107" height="114" transform="rotate(90 146.5 28.5)" stroke="url(#paint1_linear_0_1)" strokeWidth="15" shapeRendering="crispEdges"/>
    </g>
    <defs>
    <filter id="filter0_d_0_1" x="37" y="27" width="172" height="179" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="12.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
    </filter>
    <filter id="filter1_d_0_1" x="0" y="-7.62939e-06" width="179" height="172" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="12.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_0_1" x1="50.1432" y1="28.195" x2="143.651" y2="235.439" gradientUnits="userSpaceOnUse">
    <stop offset="0.194187" stopColor="#2145A3"/>
    <stop offset="1" stopColor="#0B193C"/>
    </linearGradient>
    <linearGradient id="paint1_linear_0_1" x1="142.143" y1="1.19502" x2="235.651" y2="208.439" gradientUnits="userSpaceOnUse">
    <stop offset="0.194187" stopColor="#2145A3"/>
    <stop offset="1" stopColor="#0B193C"/>
    </linearGradient>
    </defs>
    </svg>
    

  )
}

export default BackgroundSVG