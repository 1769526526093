import './Projects.scss'
// Components Import
import Landing from './Sections/Landing/Landing'
import ProjectsSec from './Sections/ProjectsSec/ProjectsSec'


let Projects = () => {


  window.scrollTo(0,0)

  return (
    <div className="projects">
      <Landing />
      <ProjectsSec />
    </div>
  )
}


export default Projects