import React, { useContext } from "react";
import { context } from "../../../../App";
import { AboutUs } from "../../../../Assets/typeScript/interfaces";
import "./Landing.scss";

let Landing: React.FC<AboutUs> = ({ header_desc, arabic_header_desc }) => {
  let isArabic = useContext(context)?.isArabic;
  let landingHeader = useContext(context)?.headers?.about_page;
  let arabicLandingHeader = useContext(context)?.headers?.arabic_about_page;

  return (
    <div
      className={`landing about-landing d-flex flex-column justify-content-end overflow-hidden ${isArabic && "arabic"}`}
    >
      <p className="title">{isArabic ? "من نحن ؟" : "About Us"}</p>
      <p className="desc">{isArabic ? arabicLandingHeader : landingHeader}</p>
      <div className="landing-shape">
        <svg width="524" height="524" viewBox="0 0 524 524" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M364.588 2.49967C367.921 5.83257 367.921 11.2363 364.588 14.5692L274.065 105.091L418.903 249.927L509.425 159.406C512.758 156.073 518.162 156.073 521.495 159.406C524.828 162.739 524.828 168.143 521.495 171.476L430.973 261.997L521.5 352.523C524.833 355.856 524.833 361.26 521.5 364.593C518.167 367.926 512.764 367.926 509.431 364.593L418.903 274.066L274.065 418.903L364.594 509.431C367.927 512.764 367.927 518.167 364.594 521.5C361.261 524.833 355.857 524.833 352.524 521.5L261.995 430.972L171.476 521.491C168.143 524.824 162.739 524.824 159.406 521.491C156.073 518.158 156.073 512.754 159.406 509.421L249.926 418.903L105.088 274.067L14.5693 364.584C11.2364 367.917 5.83264 367.917 2.4997 364.584C-0.833231 361.251 -0.833236 355.848 2.4997 352.515L93.0183 261.997L2.50528 171.485C-0.827658 168.152 -0.827653 162.749 2.50528 159.416C5.83822 156.083 11.242 156.083 14.5749 159.416L105.088 249.928L249.926 105.091L159.412 14.5777C156.079 11.2448 156.079 5.84111 159.412 2.50821C162.745 -0.824687 168.148 -0.824686 171.481 2.50821L261.996 93.0217L352.519 2.49967C355.852 -0.833224 361.255 -0.833225 364.588 2.49967ZM261.996 117.161L117.158 261.997L261.995 406.833L406.833 261.997L261.996 117.161Z"
            fill="url(#paint0_radial_68_71)"
          />
          <path
            d="M262 122C262 199.32 199.32 262 122 262C199.32 262 262 324.68 262 402C262 324.68 324.68 262 402 262C324.68 262 262 199.32 262 122Z"
            fill="url(#paint1_radial_68_71)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_68_71"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(262 262) rotate(90) scale(290.175)"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
            <radialGradient
              id="paint1_radial_68_71"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(262 262) rotate(90) scale(161.875)"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <div className="stars">
        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.855469 33.1603C2.61014 33.0793 4.09486 32.4044 5.30963 31.1357C6.5244 29.8669 7.14529 28.3552 7.14529 26.6005C7.14529 28.3552 7.73918 29.8669 8.95395 31.1357C10.1687 32.4044 11.6534 33.0793 13.4081 33.1603C11.6534 33.2413 10.1687 33.9162 8.95395 35.1849C8.36664 35.783 7.90425 36.4921 7.59373 37.2707C7.28321 38.0493 7.13077 38.8819 7.14529 39.7201C7.14529 37.9654 6.5514 36.4537 5.30963 35.1849C4.09486 33.9162 2.61014 33.2413 0.855469 33.1603ZM7.14529 13.724C10.5466 13.562 13.4351 12.2392 15.8107 9.7557C18.1862 7.27217 19.374 4.32972 19.374 0.901367C19.374 4.32972 20.5618 7.27217 22.9373 9.7557C25.3129 12.2392 28.2013 13.535 31.6297 13.724C29.3891 13.8319 27.3375 14.4528 25.4478 15.6406C23.5852 16.8014 22.1005 18.3671 20.9937 20.3107C19.9139 22.2544 19.374 24.333 19.374 26.6005C19.374 23.1722 18.1862 20.2027 15.8107 17.7192C13.4351 15.2087 10.5466 13.8859 7.14529 13.724ZM16.1076 42.7975C18.6721 42.6895 20.8587 41.6907 22.6404 39.8281C24.422 37.9654 25.3129 35.7518 25.3129 33.1603C25.3129 35.7518 26.2037 37.9654 27.9854 39.8281C29.767 41.6907 31.9266 42.6895 34.4911 42.7975C31.9266 42.9055 29.767 43.9043 27.9854 45.7669C26.2037 47.6296 25.3129 49.8432 25.3129 52.4347C25.3129 49.8432 24.422 47.6296 22.6404 45.7669C20.9375 43.9572 18.5908 42.8905 16.1076 42.7975ZM34.4911 30.0019C37.0557 29.8939 39.2153 28.8951 40.9969 27.0325C42.7786 25.1698 43.6424 22.9562 43.6424 20.3377C43.6424 22.9292 44.5333 25.1428 46.3149 27.0055C48.0966 28.8681 50.2832 29.8669 52.8477 29.9749C50.2832 30.0829 48.0966 31.0817 46.3149 32.9443C44.5333 34.807 43.6424 37.0206 43.6424 39.6121C43.6424 37.0206 42.7516 34.807 40.9969 32.9443C39.2153 31.1087 37.0557 30.1099 34.4911 30.0019Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default Landing;
