import React, { useContext } from "react";
import { context } from "../../../../App";
import HeadingSVG from "../../../../Assets/Svg/HeadingSVG";
import { AboutUs } from "../../../../Assets/typeScript/interfaces";
import BackgroundSVG from "../../Svg/BackgroundSVG";
import "./OurTeam.scss";

let OurTeam:React.FC<AboutUs> = ({team,arabic_team, team_image}) => {
  let isArabic = useContext(context)?.isArabic
  return (
    <div className="team d-flex align-items-center justify-content-between" style={isArabic? {direction: 'rtl'}: {}}>
      <div className="wrapper w-100">
        <p className="heading gap-3">
          <HeadingSVG />
          {isArabic ? 'فريقنا' : 'Our Team'}
        </p>
        <div className="text" >
          <BackgroundSVG />
          
          <div dangerouslySetInnerHTML={{
							__html: `${isArabic? arabic_team : team}`?? '',
						}}></div>
          <BackgroundSVG />
        </div>
      </div>
      <img
        src={`https://dashboard.imanagement.sa/${team_image}`}
        alt="Team"
      />
    </div>
  );
};



export default OurTeam;
