import './ProductBox.scss'
import HeadphonesSVG from '../../Assets/Svg/HeadphonesSVG'
import { Product } from '../../Assets/typeScript/interfaces'
import { useContext } from 'react'
import { context } from '../../App'

interface ProductPorps extends Product {
  showProducts: Function
  i: number
}

const ProductBox:React.FC<ProductPorps> = ({id, name,arabic_name, description,arabic_description, image, showProducts ,i}) => {
  let isArabic = useContext(context)?.isArabic
  return (
    <div className="product" style={isArabic? {direction: 'rtl'}: {}}>
    <img
      src={image}
      alt={name}
    />
    <div className="product-info">
      <p className="product-name">{isArabic? arabic_name : name}</p>
     
      <p
                    className="product-desc"
                    dangerouslySetInnerHTML={{ __html: `${isArabic? arabic_description : description}` }}
        ></p>
      <button className="see-more" onClick={(e) => showProducts(e.currentTarget, id, i)}>See More</button>
    </div>
  </div>
  )
}

export default ProductBox