import { useContext } from 'react'
import { context } from '../../App'
import { SolutionDetails } from '../../Assets/typeScript/interfaces'
import './SolutionBox.scss'

interface SolutionBoxProps extends SolutionDetails {
  activateSolution: Function,
  active:boolean
}

const SolutionBox:React.FC<SolutionBoxProps> = ({id, name,arabic_name, service_icon, service_icon_white, activateSolution, active }: SolutionBoxProps) => {
  let isArabic = useContext(context)?.isArabic
  return (
    <div
    className={`solution-box ${active? 'active': ''}`}
    onClick={(e) => {activateSolution && activateSolution(e.currentTarget, id)}}
    id={`${id}`}
  >
    <div className="hover-box"></div>
    <div className="svgs">
      <img src={service_icon} alt={name} />
      <img src={service_icon_white} alt={name} />
    </div>
    <div className="solution-name">{isArabic ? arabic_name: name}</div>
  </div>
  )
}

export default SolutionBox