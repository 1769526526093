import React, { useContext } from 'react'
import { context } from '../../../../App'
import HeadingSVG from '../../../../Assets/Svg/HeadingSVG'
import { AboutUs } from '../../../../Assets/typeScript/interfaces'
import './OurVision.scss'

let OurVision:React.FC<AboutUs> = ({vision,arabic_vision, vision_image}) => {
  let isArabic = useContext(context)?.isArabic
  return (
    <div className="vision" style={isArabic? {direction: 'rtl'}: {}}>
        <img src={`https://dashboard.imanagement.sa/${vision_image}`} alt="Mountains" />
        <div className="wrapper">
      <p className={`heading gap-3`}>
        <HeadingSVG/>
        {isArabic? 'رؤيتنا' : 'Our Vision'}
      </p>
      <div className="text" dangerouslySetInnerHTML={{
							__html: `${isArabic? arabic_vision: vision}`?? '',
						}}>

      </div>
      </div>

    </div>
  )
}

export default OurVision