import { useContext } from "react";
import { context } from "../../../../App";
import StarsSVG from "../../../../Assets/Svg/StarsSVG";
import "./Landing.scss";

const Landing = () => {
  let isArabic = useContext(context)?.isArabic
  let landingHeader = useContext(context)?.headers?.projects_page
  let arabicLandingHeader = useContext(context)?.headers?.arabic_projects_page
  return (
    <div className={`landing projects-landing d-flex flex-column justify-content-end overflow-hidden ${isArabic && 'arabic'}`}>
      <p className="title">{isArabic? "مشاريعنا" : "Our Projects"}</p>
      <p className="desc">
        {isArabic? arabicLandingHeader : landingHeader}
      </p>
      <div className="landing-shape">
      <svg width="602" height="602" viewBox="0 0 602 602" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M138.788 231.314L208.182 300.708L138.788 370.102L69.394 300.708L138.788 231.314Z" fill="url(#paint0_radial_6_650)"/>
<path d="M370.102 138.788L300.708 69.3942L231.314 138.788L300.708 208.183L370.102 138.788Z" fill="url(#paint1_radial_6_650)"/>
<path d="M462.628 231.314L532.022 300.708L462.628 370.102L393.234 300.708L462.628 231.314Z" fill="url(#paint2_radial_6_650)"/>
<path d="M300.708 393.234L370.102 462.628L300.708 532.022L231.314 462.628L300.708 393.234Z" fill="url(#paint3_radial_6_650)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M346.971 277.577L323.839 254.445L439.496 138.788L300.708 0L161.92 138.788L277.577 254.445L254.445 277.577L138.788 161.92L-0.000137639 300.708L138.788 439.497L254.445 323.84L277.577 346.971L161.92 462.628L300.708 601.416L439.496 462.628L323.839 346.971L346.971 323.84L462.628 439.497L601.416 300.708L462.628 161.92L346.971 277.577ZM427.931 138.788L300.708 11.5657L173.485 138.788L283.359 248.663L294.925 237.097L196.617 138.788L300.708 34.6971L404.799 138.788L289.142 254.445L300.708 266.011L427.931 138.788ZM294.925 271.794L283.359 260.228L260.228 283.36L271.794 294.925L294.925 271.794ZM300.708 277.577L277.577 300.708L300.708 323.84L323.839 300.708L300.708 277.577ZM329.622 294.925L341.188 283.36L318.057 260.228L306.491 271.794L329.622 294.925ZM329.622 306.491L306.491 329.622L318.057 341.188L341.188 318.057L329.622 306.491ZM462.628 173.485L589.85 300.708L462.628 427.931L451.062 416.365L566.719 300.708L462.628 196.617L346.971 312.274L335.405 300.708L462.628 173.485ZM266.011 300.708L254.445 289.142L127.223 416.365L138.788 427.931L266.011 300.708ZM260.228 318.057L283.359 341.188L294.925 329.622L271.794 306.491L260.228 318.057ZM173.485 462.628L283.359 572.502L294.925 560.936L196.617 462.628L312.274 346.971L300.708 335.405L173.485 462.628ZM318.057 352.754L427.931 462.628L300.708 589.851L289.142 578.285L404.799 462.628L306.491 364.319L318.057 352.754ZM248.662 283.36L237.097 294.925L138.788 196.617L34.697 300.708L133.005 399.017L121.44 410.582L11.5656 300.708L138.788 173.485L248.662 283.36ZM138.788 208.183L231.314 300.708L138.788 393.234L46.2627 300.708L138.788 208.183ZM300.708 231.314L208.182 138.788L300.708 46.2628L393.234 138.788L300.708 231.314ZM370.102 300.708L462.628 393.234L555.153 300.708L462.628 208.183L370.102 300.708ZM364.319 306.491L352.754 318.057L445.279 410.582L456.845 399.017L364.319 306.491ZM300.708 370.102L208.182 462.628L300.708 555.153L393.234 462.628L300.708 370.102Z" fill="url(#paint4_radial_6_650)"/>
<defs>
<radialGradient id="paint0_radial_6_650" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(300.708 300.708) rotate(178.919) scale(306.546)">
<stop stopColor="white"/>
<stop offset="0.476009" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint1_radial_6_650" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(300.708 300.708) rotate(178.919) scale(306.546)">
<stop stopColor="white"/>
<stop offset="0.476009" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint2_radial_6_650" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(300.708 300.708) rotate(178.919) scale(306.546)">
<stop stopColor="white"/>
<stop offset="0.476009" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial_6_650" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(300.708 300.708) rotate(178.919) scale(306.546)">
<stop stopColor="white"/>
<stop offset="0.476009" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint4_radial_6_650" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(300.708 300.708) rotate(178.919) scale(306.546)">
<stop stopColor="white"/>
<stop offset="0.476009" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
</defs>
      </svg>


      </div>
      <StarsSVG />
    </div>
  );
};

export default Landing;
