import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { context } from "../../../../App";
import { WebsiteData } from "../../../../Assets/typeScript/interfaces";
import "./HomeLanding.scss";

interface HomeLandingProps {
  header_desc: string;
  arabic_header_desc: string;
}

let HomeLanding: React.FC<WebsiteData> = ({ header_desc, arabic_header_desc }: WebsiteData) => {
  // isArabic:
  let isArabic = useContext(context)?.isArabic;
  // navigation funciton
  let navigate = useNavigate();

  // Scroll to contact Form
  function scrollToContact() {
    let contactSection = document.querySelector(".contact") as HTMLElement;
    window.scrollTo(0, contactSection.offsetTop - 100);
  }

  useEffect(() => {
    const videoElement = document.getElementById("landingVideo") as HTMLVideoElement;
    videoElement?.play();
  }, []);

  return (
    <div className="home-landing d-flex flex-column align-items-center justify-content-center">
      <h1 className="landing-heading">{isArabic ? "الادارة التفاعلية" : "Interactive Management"}</h1>
      <p className="comp-desc text-center">{isArabic ? arabic_header_desc : header_desc}</p>

      <button className="cta-btn" onClick={window.innerWidth > 768 ? scrollToContact : () => navigate("/contact")}>
        {isArabic ? "تواصل معنا" : "Let's Connect"}
      </button>
      <video id="landingVideo" autoPlay={true} loop={true} muted playsInline preload="auto">
        <source src="/landingBackground.webm" type="video/webm" />
      </video>
    </div>
  );
};

export default HomeLanding;
