import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { context } from "../../App";
import "./ProjectsPage.scss";

const ProjectPage = () => {
  const slug = useParams().projectSlug;
  const [projectData, setProjectData] = useState<any>(null);
  const projectsURL = `${useContext(context)?.URL}/projects/`;
  let isArabic = useContext(context)?.isArabic;

  function replaceAllSubstrings(originalString: string, substringToReplace: string, replacementString: string) {
    // Escape special characters in the substring to replace to use it in a regular expression
    const escapedSubstring = substringToReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    // Create a regular expression with the global flag to replace all occurrences
    const regex = new RegExp(escapedSubstring, "g");
    // Use the replace method with the regular expression to replace all occurrences
    return originalString.replace(regex, replacementString);
  }

  useEffect(() => {
    axios.get(projectsURL).then((res) => {
      const foundProject = res.data.find((project: any) => slug === project.slug);
      if (foundProject) {
        setProjectData(foundProject);
      }
    });
  }, []);

  if (!projectData) return null;
  // {
  //   "id": 17,
  //   "name": "Banque Saudi Fransi",
  //   "arabic_name": "البنك السعودي الفرنسي",
  //   "image": "https://dashboard.imanagement.sa/media/images/projects/bsf.gif",
  //   "cover_image": "https://dashboard.imanagement.sa/media/images/projects/CR.jpg",
  //   "slug": "banque-saudi-fransi",
  //   "arabic_description": "<h1>Headline 1</h1>\r\n\r\n<h2>Headline 2</h2>\r\n\r\n<h3>Headline 3</h3>\r\n\r\n<h4>Headline 4</h4>\r\n\r\n<h4>&nbsp;</h4>\r\n\r\n<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo fugiat culpa eaque, illum, est temporibus qui consectetur magnam quasi id doloribus optio corrupti molestiae amet, <a href=\"https://google.com\">ut mollitia</a> <u>autem!</u> <em>Esse,</em> <strong>natus?</strong></p>\r\n\r\n<p><strong><img alt=\"\" src=\"/media/uploads/2024/08/11/cr.jpg\" style=\"height:990px; width:1320px\" /></strong></p>",
  //   "english_description": "<h1>Headline 1</h1>\r\n\r\n<h2>Headline 2</h2>\r\n\r\n<h3>Headline 3</h3>\r\n\r\n<h4>Headline 4</h4>\r\n\r\n<h4>&nbsp;</h4>\r\n\r\n<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo fugiat culpa eaque, illum, est temporibus qui consectetur magnam quasi id doloribus optio corrupti molestiae amet, <a href=\"https://google.com\">ut mollitia</a> <u>autem!</u> <em>Esse,</em> <strong>natus?</strong></p>\r\n\r\n<p><strong><img alt=\"\" src=\"/media/uploads/2024/08/11/cr.jpg\" style=\"height:990px; width:1320px\" /></strong></p>"
  // }
  //
  return (
    <div>
      {/* Landing Section */}
      <section className="projectPageLanding">
        <h1>{isArabic ? projectData?.arabic_name : projectData?.name}</h1>
        {projectData.cover_image && <img src={projectData.cover_image} />}
      </section>
      <section className="projectPageContent">
        <div
          dangerouslySetInnerHTML={{
            __html: isArabic
              ? replaceAllSubstrings(
                  projectData.arabic_description,
                  'src="/media/uploads',
                  'src="https://dashboard.imanagement.sa/media/uploads'
                )
              : replaceAllSubstrings(
                  projectData.english_description,
                  'src="/media/uploads',
                  'src="https://dashboard.imanagement.sa/media/uploads'
                ),
          }}
        />
      </section>
    </div>
  );
};

export default ProjectPage;
