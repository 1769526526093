import React from 'react'

const MailMessageSVG = () => {
  return (
    <svg
                  width="47"
                  height="36"
                  viewBox="0 0 47 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M46.0029 0.016467C45.7862 -0.005489 45.5678 -0.005489 45.3511 0.016467H0.842485C0.557223 0.0207895 0.27385 0.0628531 0 0.141525L22.9696 22.6363L46.0029 0.016467Z"
                    fill="#07074E"
                  />
                  <path
                    d="M45.8304 3.651L24.8991 24.2556C24.3604 24.7849 23.6316 25.0821 22.8721 25.0821C22.1125 25.0821 21.3838 24.7849 20.8451 24.2556L0.100631 3.80731C0.036859 4.03899 0.0030458 4.27773 0 4.51782V32.938C0 33.6917 0.30292 34.4146 0.842121 34.9476C1.38132 35.4806 2.11263 35.78 2.87518 35.78H43.1277C43.8902 35.78 44.6216 35.4806 45.1608 34.9476C45.7 34.4146 46.0029 33.6917 46.0029 32.938V4.51782C45.9914 4.22176 45.9332 3.92928 45.8304 3.651ZM4.84468 32.938H2.84643V30.9059L13.2977 20.6605L15.3247 22.6641L4.84468 32.938ZM43.0989 32.938H41.0863L30.6063 22.6641L32.6333 20.6605L43.0846 30.9059L43.0989 32.938Z"
                    fill="#07074E"
                  />
                </svg>
  )
}

export default MailMessageSVG