import React from 'react'

const AddressSVG = () => {
  return (
    <svg
    width="35"
    height="43"
    viewBox="0 0 35 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2768 2.15314e-08C12.7245 -0.000226587 8.35604 1.78828 5.12093 4.97678C1.88583 8.16529 0.0455231 12.4961 0 17.0279C0 28.8099 15.2252 41.9249 15.8731 42.4839C16.2642 42.817 16.762 43 17.2768 43C17.7915 43 18.2894 42.817 18.6805 42.4839C19.4364 41.9249 34.5536 28.8099 34.5536 17.0279C34.5081 12.4961 32.6678 8.16529 29.4326 4.97678C26.1975 1.78828 21.8291 -0.000226587 17.2768 2.15314e-08ZM17.2768 23.6499C15.7818 23.6499 14.3205 23.2086 13.0775 22.3817C11.8345 21.5549 10.8657 20.3796 10.2936 19.0046C9.72147 17.6296 9.57178 16.1166 9.86343 14.6569C10.1551 13.1972 10.875 11.8564 11.9321 10.804C12.9891 9.7516 14.336 9.03491 15.8022 8.74456C17.2684 8.45421 18.7882 8.60323 20.1693 9.17278C21.5505 9.74232 22.731 10.7068 23.5615 11.9443C24.3921 13.1818 24.8354 14.6366 24.8354 16.1249C24.8354 18.1207 24.039 20.0347 22.6215 21.4459C21.204 22.8571 19.2815 23.6499 17.2768 23.6499Z"
      fill="#07074E"
    />
  </svg>
  )
}

export default AddressSVG