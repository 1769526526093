import { useContext } from 'react'
import { context } from '../../../../App'
import HeadingSVG from '../../../../Assets/Svg/HeadingSVG'
import ContactForm from '../../../../Components/ContactForm/ContactForm'
import Footer from '../../../../Components/Footer/Footer'
import './Contact.scss'

interface SetShown {
  setShown: Function
}

let Contact:React.FC<SetShown> = ({setShown}) => {
  let isArabic = useContext(context)?.isArabic

  

  return (
    <div className="contact" id='contact-form'>
      <div className="collage me-5">
        <img src={require('../../../../Assets/Images/collage.png')} alt="Collage" />
      </div>
      <div className="form text-center">
        <div className="section-info">
        <p className={`heading gap-3`} style={{direction: 'rtl'}}>
         <HeadingSVG />
          {!isArabic && ((window.innerWidth < 992 )? `Tell us your Need,\n and let\'s connect!` : "Send Us a message")}
          {isArabic && ((window.innerWidth < 992 )? `اخبرنا باحتياجاتك,\n ودعنا نتواصل!` : "ارسل لنا رسالة")}

        </p>
        </div>
        <ContactForm setShown={setShown} />
      </div>
    </div>
  )
}


export default Contact