import "./App.scss";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
// Dependincies import
import "bootstrap/dist/css/bootstrap.min.css";
// Components Import
import Header from "./Components/Header/Header";
import Solutions from "./Pages/Solutions/Solutions.";
import Home from "./Pages/Home/Home";
import ScrollHeader from "./Components/ScrollHeader/ScrollHeader";
import About from "./Pages/About/About";
import Products from "./Pages/Products/Products";
import Projects from "./Pages/Projects/Projects";
import Contact from "./Pages/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import React, { createContext } from "react";
import axios from "axios";
import { ContextProps, LandingHeaders, WebsiteData } from "./Assets/typeScript/interfaces";
import NewsPage from "./Pages/NewsPage/NewsPage";
import ProductPage from "./Pages/ProductPage/ProductPage";
import ProjectPage from "./Pages/ProjectPage/ProjectPage";

export const context = createContext<ContextProps | null>(null);

function App() {
  // Api Url \ isArabic state
  const URL: string = "https://dashboard.imanagement.sa/api";
  let arabic = localStorage.getItem("isArabic") == "true" ? true : false;
  let [isArabic, setIsArabic] = React.useState<boolean>(arabic);

  // set language based on local storage item:

  // set Arabic function:
  function setArabic() {
    // set language in local storage
    window.location.reload();
    localStorage.setItem("isArabic", `${!isArabic}`);
    // Switch language
    setIsArabic((prev) => !prev);
  }

  // get Pages Landing Headers
  let [headers, setHeaders] = React.useState<LandingHeaders>();
  const headersURL = `${URL}/landing_headers/`;
  React.useEffect(() => {
    axios.get(headersURL).then((response) => setHeaders(response.data[0]));
  }, []);

  // Header/ Footer Data
  const websiteDataURL = `${URL}/website_data/`;
  const [data, setData] = React.useState<WebsiteData>();

  React.useEffect(() => {
    axios.get(websiteDataURL).then((response) => setData(response.data));
  }, []);

  // Header appear on scroll:
  window.addEventListener("scroll", () => {
    let header = document.querySelector(".scroll-header");
    if (header instanceof HTMLElement) {
      // show header if scrolled more then 250px
      if (window.scrollY > 250) {
        header.style.transform = "translateY(0%)";
      } else {
        header.style.transform = "translateY(-100%)";
      }
    }
  });

  return (
    <context.Provider value={{ URL, isArabic, headers }}>
      <div className={`App ${isArabic && "arabic"}`}>
        <BrowserRouter>
          <Header {...data} setArabic={setArabic} />
          <ScrollHeader {...data} setArabic={setArabic} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/news/:newsId" element={<NewsPage />} />
            <Route path="/product/:productId" element={<ProductPage />} />
            <Route path="/projects/:projectSlug" element={<ProjectPage />} />
          </Routes>
          <Footer {...data} />
        </BrowserRouter>
      </div>
    </context.Provider>
  );
}

export default App;
