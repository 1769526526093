import './About.scss'
// Components Import
import Landing from './Sections/Landing/Landing'
import CMWord from './Sections/CMWord/CMWord'
import OurVision from './Sections/OurVision/OurVision'
import OurTeam from './Sections/OurTeam/OurTeam'
import Certificate from './Sections/Certificate/Certificate'
import Footer from '../../Components/Footer/Footer'
import React, { useContext } from 'react'
import axios from 'axios'
import { AboutUs } from '../../Assets/typeScript/interfaces'
import { context } from '../../App'

let About = () =>{
  React.useEffect(() => {
    window.scrollTo(0,0)
  }, [])

    const dataURL = `${useContext(context)?.URL}/about_us/`
    const [data, setData] = React.useState<AboutUs>()
   
    React.useEffect(() => {
      axios.get(dataURL)
      .then(response => setData(response.data))
    }, [])

  return (
    <div className="about">
      <Landing {...data} />
      <CMWord {...data}/>
      <OurVision {...data}/>
      <OurTeam {...data}/>
      <Certificate/>
    </div>
  )
}

export default About