import './Landing.scss'
import React from 'react'
import StarsSVG from '../../../../Assets/Svg/StarsSVG'
import { NewsProps } from '../../../../Assets/typeScript/interfaces'


const Landing:React.FC<NewsProps> = ({title, desc}) => {
    return (
      <div className="landing news-landing d-flex flex-column justify-content-center align-items-center overflow-hidden text-center">
        <p className="title news-title m-0 mb-2">{title}</p>
        <StarsSVG />
      </div>
    )
  
}

export default Landing