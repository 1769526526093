import React from "react";
import "./ProductsPage.scss";
import Landing from "../Products/Sections/Landing/Landing";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { context } from "../../App";
import { Navigation, Autoplay } from "swiper";
import "swiper/css/navigation";

const ProductPage = () => {
  let isArabic = useContext(context).isArabic;

  const id = useParams().productId;
  // get news Data
  const productsUrl = `${useContext(context)?.URL}/products/`;
  const subProductsUrl = `${useContext(context)?.URL}/sub_products/`;
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    let links = document.querySelectorAll(".link.active");
    for (let i = 0; i < 2; i++) {
      links[i].classList.remove("active");
    }
  }, []);

  React.useEffect(() => {
    axios.get(productsUrl).then((products) => {
      axios.get(subProductsUrl).then((sub_products) => {
        let product = products.data.find(
          (product) => product.id === parseInt(id)
        );
        let data = {
          ...product,
          sub_products: sub_products.data.filter(
            (sub) => sub.product === parseInt(id)
          ),
        };
        setData(data);
      });
    });
  }, []);

  return (
    <div className="product-page">
      <Landing />
      {/* Start big Image */}
      <div className="big-image">
        <img src={data && data.image} alt="" />
        <div className="info">
          <h3 className="product-name">
            {data && (isArabic ? data.arabic_name : data.name)}
          </h3>
          <p
            className="product-desc"
            dangerouslySetInnerHTML={{
              __html: `${
                isArabic ? data.arabic_description : data.description
              }`,
            }}
          ></p>
        </div>
      </div>
      {/* End big Image */}
      {/* Start SubProducts */}
      <div
        className="sub-products-section"
        style={{ direction: isArabic && "rtl" }}
      >
        {data.sub_products &&
          data.sub_products.map((sub) => {
            return (
              <div className="sub-product-slider">
                <div className="image-container">
                  <img src={sub.image} alt="sub-product" />
                  <div className="sub-product-info">
                    {isArabic ? sub.arabic_name : sub.name}
                  </div>
                </div>
                <div className="fragments-slider">
                  <Swiper
                    // slidesPerView={sub.sub_product_branch.length > 3 ? 4 : 3}
                    slidesPerView={
                      window.innerWidth > 991 ?
                      (sub.sub_product_branch.length > 3 ? 4 : 3) :
                      ( 2 )
                    }
                    spaceBetween={
                      window.innerWidth > 991 ?
                      (sub.sub_product_branch.length === 2 ? 100 : 
                      sub.sub_product_branch.length < 4 ? -200 : 100) :
                      (
                        200
                      )
                      
                    }
                    slidesPerGroup={1}
                    loop={false}
                    autoplay= {{
                      delay: 2500,
                      disableOnInteraction: true
                    }}
                    modules={[Navigation, Autoplay]}
                    navigation= { true }
                  >
                    {sub.sub_product_branch &&
                      sub.sub_product_branch.map((frag) => {
                        return (
                          <SwiperSlide>
                            <div className="fragment">
                              <div className="image-container">
                                <img src={frag.image} alt="fragment" />
                                <div className="download">
                                  <a
                                    href={frag.brochure}
                                    target="_blank"
                                    className="download-btn"
                                  >
                                    <FontAwesomeIcon icon={faArrowDown} />
                                  </a>
                                  <p>Download Brochure</p>
                                </div>
                              </div>
                              <div className="frag-info">
                                <h4 className="frag-name">
                                  {isArabic ? frag.arabic_name : frag.name}
                                </h4>
                                <p className="frag-desc">{frag.desc}</p>
                              </div>
                            </div>
                          </SwiperSlide>
                          
                        );
                      })}
                      {sub.sub_product_branch.length > 3 && <SwiperSlide className="h-100"/>}
                      <SwiperSlide className="h-100" />
                  </Swiper>
                </div>
              </div>
            );
          })}
      </div>
      {/* End SubProducts */}
    </div>
  );
};

export default ProductPage;
