import Testimonial from "../../../../Components/Testimonial/Testimonial";
import "./Testimonials.scss";
// interfaces Import
// import { TestimonialInfoProps } from "../../../../Assets/typeScript/interfaces";
import React from "react";
import axios from "axios";
import { TestimonialProps } from "../../../../Assets/typeScript/interfaces";
import Slider from "react-slick";

let Testimonials:React.FC<TestimonialProps[]> = (testimonials) => {
  let testimonialsData = Object.values(testimonials) 
  console.log(testimonialsData)
  

  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: testimonialsData.length > 3 ? 3 : 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

return (
  
    <div className='testimonials text-center'>
      <div className="section-info mb-5">
        <p className="heading">
          Testimonials
        </p>
      </div>

      <Slider {...settings}>
        {testimonialsData.map((testimonial) => {
          return (
            <Testimonial {...testimonial} />
          )
        })}
      </Slider>

    </div>
  );
};

export default Testimonials;
