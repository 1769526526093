import './LatestNews.scss'

import React, { useContext } from 'react'
import HeadingSVG from '../../../../Assets/Svg/HeadingSVG'
import Slider from 'react-slick'
import News from '../../../../Components/News/News'
import axios from 'axios'
import { context } from '../../../../App'
import { NewsProps } from '../../../../Assets/typeScript/interfaces'

const LatestNews = () => {
  let isArabic = useContext(context)?.isArabic

  // get news Data
  const NewsURL = `${useContext(context)?.URL}/news/`
  // const NewsURL = `http://127.0.0.1:8000/api/news/`
  const [news, setNews] = React.useState<NewsProps[]>([])
  React.useEffect(() => {
    axios.get(NewsURL)
    .then(response => {
      setNews(response.data)
    })
  }, [])

  
  

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,
    autoplaySpeed: 3000,
    autoplay: true,
    selectedTab: false,
  };



  return (
    <div className='news-section'>
        <div className="section-info d-flex justify-content-center">
          <p className="heading" style={isArabic? {direction: 'rtl', gap: '1rem'}: {}}>
            <HeadingSVG />
            {isArabic? 'اخر الاخبار' : 'Latest News'}
          </p>
        </div>
        <div className="news-container">
          <Slider {...settings}> 
              {
                news.map(news => {
                  return(
                    <News {...news} />
                  )
                })
              }
          </Slider>
        </div>
    </div>
  )
}

export default LatestNews