import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactInfo.scss";
// Components Import
import ContactForm from "../../../../Components/ContactForm/ContactForm";
import PhoneSVG from "../../../../Assets/Svg/PhoneSVG";
import AddressSVG from "../../../../Assets/Svg/AddressSVG";
import MailMessageSVG from "../../../../Assets/Svg/MailMessageSVG";
import React, { useContext } from "react";
import axios from "axios";
import { WebsiteData } from "../../../../Assets/typeScript/interfaces";
import { context } from "../../../../App";

interface SetShown {
  setShown: Function;
}

const ContactInfo: React.FC<SetShown> = ({ setShown }) => {
  let isArabic = useContext(context)?.isArabic;
  // Remote Data
  const infoURL = `${useContext(context)?.URL}/website_data/`;
  const [info, setInfo] = React.useState<WebsiteData>();
  React.useEffect(() => {
    axios.get(infoURL).then((response) => {
      setInfo(response.data);
    });
  }, []);

  return (
    <div className={`contact-info ${isArabic && "arabic"}`}>
      <div className="map">
        <iframe
          title="Company location"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d888.3493809915835!2d46.7469844!3d24.6867003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f040286ca8a73%3A0x5f114419f020b16!2s4476%20Al%20Hamdi%2C%206880%2C%20Ar%20Rabwah%2C%20Riyadh%2012816%2C%20Saudi%20Arabia!5e1!3m2!1sen!2s!4v1668786444196!5m2!1sen!2s"
          width="800"
          height="600"
        ></iframe>
      </div>
      <div className="contact-container d-flex  justify-content-start">
        <div className="contact-form">
          <div className="form-header">
            {isArabic ? "راسلنا" : "Send Us a Message"}
          </div>
          <ContactForm setShown={setShown} />
        </div>
        <div className="info text-start">
          <p className="info-header">
            {isArabic ? "تواصل معنا" : "Get In Touch With Us"}
          </p>
          <ul className="contact-details">
            <li>
              <div className="info-title">
                <PhoneSVG />
                <p className="info-name">{isArabic ? "الهاتف" : "Phone"}</p>
              </div>
              <a href={`tel:${info?.phone_number}`}>{info?.phone_number}</a>
            </li>
            <li>
              <div className="info-title">
                <AddressSVG />

                <p className="info-name">{isArabic ? "العنوان" : "Address"}</p>
              </div>
              <p className="">{info?.address}</p>
            </li>
            <li className="email">
              <div className="info-title">
                <MailMessageSVG />

                <p className="info-name">
                  {isArabic ? "البريد الالكتروني" : "Email"}
                </p>
              </div>
              <a href={`${info?.email}`}>{info?.email}</a>
            </li>
          </ul>
          <div className="social-media-links">
            <div className="social-media-title">
              {isArabic ? "التواصل الاجتماعي" : "Social Media"}
            </div>
            <div className="links">
              <a href={info?.linkedin_link}>
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a href={info?.facebook_link}>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href={info?.twitter_link}>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href={info?.whatsapp_link} target="_blank">
                <FontAwesomeIcon icon={faWhatsapp} className="fs-2" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
