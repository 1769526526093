import "./SolutionPreview.scss";
import {BranchesDetails, SolutionDetails} from '../../Assets/typeScript/interfaces'
import { useContext } from "react";
import { context } from "../../App";

let SolutionPreview: React.FC<SolutionDetails> = ({id, name,arabic_name, description,arabic_description, description_background, sub_solutions}) => {
  let isArabic = useContext(context)?.isArabic
  return (
    <div className={`solution-preview ${isArabic && 'arabic'}`} style={isArabic? {direction: 'rtl'}: {}}>
      <div className="content">
        <p className="solution-title heading">{isArabic? arabic_name :name}</p>
        <div className="solution-image">
          <img
            src={`${description_background}`}
            alt="solution-preview-image"
          />
          <div className="solution-desc" dangerouslySetInnerHTML={{__html: isArabic ? arabic_description : description}} />
        </div>
       
        
          <div className="solution-branches">
            {sub_solutions.map(({id, name,arabic_name, background, file}) => {
              return (
                <div className="solution-branch" key={id}>
              <img
                src={`${background}`}
                alt={name}
              />
              <div className="branch-name">
                <p> {isArabic ? arabic_name : name} </p>
                <button className="download phone-btn">Download</button>
              </div>
              <div className="download-overlay">
                <p className="name">
                  {isArabic ? arabic_name : name}
                </p>
                <a href={file} download target='_blank' className="download text-decoration-none text-white">Download</a>
              </div>
            </div>
              )
            })}
{/* 
            <div className="solution-branch">
              <img
                src={require("../../Assets/Images/city-branch1.avif")}
                alt="Branch-1"
              />
              <div className="branch-name">
                <p> Smart Cities and Remote Monitoring & Control Applications </p>
                <button className="download phone-btn">Download</button>
              </div>
              <div className="download-overlay">
                <p className="name">
                  Smart Cities and Remote Monitoring & Control Applications
                </p>
                <button className="download">Download</button>
              </div>
            </div>
            <div className="solution-branch">
              <img
                src={require("../../Assets/Images/city-branch2.avif")}
                alt="Branch-2"
              />
              <div className="branch-name">
                <p> City Wide - Surveillance Infrastructure </p>
                <button className="download phone-btn">Download</button>

              </div>
              <div className="download-overlay">
                <p className="name">City Wide - Surveillance Infrastructure</p>
                <button className="download">Download</button>
              </div>
            </div>
            <div className="solution-branch">
              <img
                src={require("../../Assets/Images/city-branch3.avif")}
                alt="Branch-3"
              />
              <div className="branch-name">
                <p> Quality Control and City Wide Monitoring System </p>
                <button className="download phone-btn">Download</button>

              </div>
              <div className="download-overlay">
                <p className="name">
                  Quality Control and City Wide Monitoring System
                </p>
                <button className="download">Download</button>
              </div>
            </div> */}
          </div>
        
      </div>
    </div>
  );
};

export default SolutionPreview;
