import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faBars, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { context } from "../../App";
import { HeaderProps } from "../../Assets/typeScript/interfaces";
import "./Header.scss";
import axios from "axios";

let MainHeader: React.FC<HeaderProps> = ({ whatsapp_link, phone_number, email, setArabic }) => {
  // is Arabic functionality:
  let isArabic = useContext(context)?.isArabic;
  let URL = useContext(context)?.URL;

  // activate Header link when navigated directly to a page other than home
  let pathname: string = useLocation().pathname;
  React.useEffect(() => {
    // activate link if it's not the home page:
    if (pathname != "/") {
      let links = document.querySelectorAll(".link");
      for (let i = 0; i < links.length; i++) {
        let link = links[i];
        if (pathname.includes(link.id)) {
          let pressed = document.getElementById(link.id) as HTMLElement;
          setTimeout(() => {
            pressed.click();
          }, 100);
        }
      }
    }
  }, []);

  // activate link funciton
  function activateLink(e: EventTarget) {
    let links = document.querySelectorAll(".link");
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("active");
    }
    if (e instanceof HTMLElement) {
      e.classList.add("active");

      if (e.firstChild instanceof HTMLElement) {
        e.firstChild.click();
        if (window.innerWidth < 768) {
          toggleSidebar("diactive");
        }
      }
    }
  }

  // Show Sidebar
  function toggleSidebar(state: string) {
    let sideBar = document.querySelector(".nav") as HTMLElement;
    if (state == "diactive") {
      sideBar.style.transform = "translateX(100%)";
    }
    if (state == "active") {
      sideBar.style.transform = "translateX(0)";
    }
  }
  // Fetch Header Link
  const [headerLinks, setHeaderLinks] = useState([]);
  useEffect(() => {
    axios.get(`${URL}/header_links/`).then((res) => {
      setHeaderLinks(res.data);
    });
  }, []);

  return (
    <header
      className={`main-header d-flex align-items-center justify-content-between w-100 ${isArabic && "arabic"} ${
        isArabic && window.innerWidth > 768 && "flex-row-reverse"
      }`}
    >
      <a href="/" className={`logo ${isArabic && "flex-row-reverse"}`}>
        <img src={require("../../Assets/Images/logos2.png")} alt="" />
      </a>
      <ul className="nav">
        <div className="bars hide-sideBar fs-2 mb-5" onClick={() => toggleSidebar("diactive")}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        {headerLinks.map(({ english_name, arabic_name, link }, i) => {
          return (
            <li
              className={`link ${i == 0 && "active"}`}
              // id="home"
              onClick={(e) => {
                activateLink(e.currentTarget);
              }}
            >
              <Link to={link}>{isArabic ? arabic_name : english_name}</Link>
            </li>
          );
        })}
        {/* <li className="link" id="about" onClick={(e) => activateLink(e.currentTarget)}>
          <Link to="/about">{isArabic ? "من نحن؟" : "About Us"}</Link>
        </li>
        <li className="link" id="solutions" onClick={(e) => activateLink(e.currentTarget)}>
          <Link to="/solutions">{isArabic ? "الحلول" : "Solutions"}</Link>
        </li>
        <li className="link" id="products" onClick={(e) => activateLink(e.currentTarget)}>
          <Link to="/products">{isArabic ? "المنتجات" : "Products"}</Link>
        </li>
        <li className="link" id="projects" onClick={(e) => activateLink(e.currentTarget)}>
          <Link to="/projects">{isArabic ? "المشاريع" : "Projects"}</Link>
        </li>
        <li className="link" id="contact" onClick={(e) => activateLink(e.currentTarget)}>
          <Link to="/contact">{isArabic ? "تواصل معنا" : "Contact"}</Link>
        </li> */}
      </ul>
      <div className="bars show-sideBar fs-2" onClick={() => toggleSidebar("active")}>
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div
        className={`icons ${window.innerWidth < 992 ? "d-none" : "d-flex"}`}
        style={isArabic ? { width: "288px" } : {}}
      >
        <a href={whatsapp_link} target="_blank" className="contact-btn text-decoration-none text-white">
          <FontAwesomeIcon icon={faWhatsapp} className=" fs-5" />
        </a>
        <a href={`tel:${phone_number}`} className="contact-btn text-decoration-none text-white">
          <FontAwesomeIcon icon={faPhone} className=" fs-6" />
        </a>
        <a href={`mailto:${email}`} className="contact-btn text-decoration-none text-white">
          <FontAwesomeIcon icon={faEnvelope} className=" fs-6" />
        </a>
        <button
          className="contact-btn lang-btn text-white fs-6 bg-transparent"
          style={{ width: "5rem", borderRadius: "10rem" }}
          onClick={() => setArabic()}
        >
          {isArabic ? "English" : "عربي"}
        </button>
      </div>
    </header>
  );
};

export default MainHeader;
